/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Card from "components/card";
import { GoPlus } from "react-icons/go";
import { FaMailBulk } from "react-icons/fa";
import SMTPAddModal from "components/modal/AddSMTPModal";
import { useToast } from "@chakra-ui/react";
import ConfirmModal from "components/modal/ConfirmModal";
import { MdDelete, MdEdit } from "react-icons/md";
import SearchBar from "views/admin/default/components/Searchbar";
import Loader from "components/Loader/Loader";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import SMTPEditModal from "components/modal/EditSMTPModal";
import { DeleteSmtpTemplate, smtpTemplateList } from "services/integrations";
import { responseHendler } from "utils/utils";
import { useTranslation } from "react-i18next";
type RowObj = {
  template_name: string;
  subject: string;
  from_name: string;
  from_email: string;
  reply_to: string;
  html_content: any;
  action: any;
};
const SMTPList = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isCreateSmtpOpen, setIsCreateSmtpOpen] = useState(false);
  const [isEditSmtpOpen, setIsEditSmtpOpen] = useState(false);
  const [rowData, setRowData] = useState<any>(null);
  const [isRequest, setIsRequest] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableDataAll, setTableDataAll] = useState<any>([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sorting, setSorting] = useState<SortingState>([]);

  useEffect(() => {
    fetchSMTPData();
  }, [refresh]);
  const fetchSMTPData = () => {
    smtpTemplateList()
      .then((response) => {
        setIsRequest(false);
        responseHendler(response);
        if (response.success) {
          setTableData(response?.data);
          setTableDataAll(response?.data);
        } else {
          toast({
            description: response?.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsRequest(false);
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    if (isConfirm === true) {
      DeleteSmtpTemplate(rowData?.id)
        .then((response) => {
          setIsConfirm(false);
          if (response.success) {
            handleConfirmClose();
            setIsRequest(false);
            setRefresh(!refresh);
            toast({
              description: t("messages.smtpTempDeleted"),
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirm]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = (e: any) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (tableDataAll) {
      const filteredData = tableDataAll.filter(
        (item: any) =>
          typeof item?.title === "string" &&
          item?.title?.toLowerCase()?.includes(value.toLowerCase())
      );
      setTableData(filteredData);
    }
  };
  const handleEdit = (rowData: any) => {
    setRowData(rowData);
    setIsEditSmtpOpen(true);
  };

  const handleConfirmClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirmModal = (rowData: any) => {
    setRowData(rowData);
    setIsConfirmModalOpen(true);
    setIsConfirm(false);
  };

  const handleOpenSmtp = () => {
    setIsCreateSmtpOpen(true);
  };
  const handleCloseSmtp = () => {
    setIsCreateSmtpOpen(false);
  };
  const handleCloseEditSmtp = () => {
    setIsEditSmtpOpen(false);
  };

  const CustomHeader = ({ headerText }: any) => (
    <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
      {headerText}
    </p>
  );
  const columns = [
    columnHelper.accessor("template_name", {
      id: "Template Name",
      header: () => (
        <CustomHeader headerText={t("integrations.smtp.templateName")} />
      ),
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("subject", {
      id: "Subject",
      header: () => (
        <CustomHeader headerText={t("integrations.smtp.subject")} />
      ),
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("action", {
      id: "Actions",
      header: () => (
        <CustomHeader headerText={t("integrations.smtp.actions")} />
      ),
      cell: (info) => (
        <div className="flex  items-center justify-end gap-4 sm:justify-center">
          <MdEdit
            onClick={() => {
              handleEdit(info?.row?.original);
              if (Array.isArray((window as any).dataLayer)) {
                (window as any).dataLayer.push({
                  event: "Edit SMTP Template",
                  buttonName: "Edit SMTP Template",
                });
              }
            }}
            color="gray"
            className="h-6 w-6 cursor-pointer"
          />
          <MdDelete
            onClick={() => {
              handleConfirmModal(info?.row?.original);
              if (Array.isArray((window as any).dataLayer)) {
                (window as any).dataLayer.push({
                  event: "Delete SMTP Template",
                  buttonName: "Delete SMTP Template",
                });
              }
            }}
            color="gray"
            className="h-6 w-6 cursor-pointer"
          />
        </div>
      ),
    }),
  ];
  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <div>
      {isRequest && <Loader />}
      <div className="mb-2">
        <div className="mt-2 flex justify-end gap-4 py-2">
          <SearchBar
            initialData={searchQuery}
            handleFilteredData={handleSearch}
          />
          <button
            onClick={() => handleOpenSmtp()}
            className="h-[46px] rounded bg-blue-700 px-6 py-0 text-white hover:bg-blue-800"
          >
            {t("integrations.smtp.newSmtp")}
          </button>
        </div>
      </div>
      {tableData && tableData?.length === 0 && !isRequest ? (
        <Card extra={"w-full sm:overflow-auto px-6"}>
          <div className="mb-auto flex flex-col items-center justify-center">
            <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
              <FaMailBulk />
            </div>
            <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
              {t("integrations.smtp.noSmtp")}
            </h4>
            <p className="px-5 text-center text-base font-normal text-gray-600 md:!px-0 xl:!px-8">
              {t("integrations.smtp.getStarted")}
            </p>
            <button
              onClick={() => handleOpenSmtp()}
              className="mb-10 mt-10 flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
            >
              <GoPlus className="h-7 w-7" />
              {t("integrations.smtp.newSmtp")}
            </button>
          </div>
        </Card>
      ) : (
        <Card extra={"w-full sm:overflow-auto px-6"}>
          <div className="mt-1 overflow-x-auto pb-4">
            <table className="responsive-table w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className="!border-px !border-gray-400"
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          scope="col"
                          className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                        >
                          <div className="items-center justify-between text-xs text-gray-200">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: "",
                              desc: "",
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell: any) => {
                      const columnHeader = cell.column.id;
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3 pr-4"
                          data-label={columnHeader}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      )}
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        handleClose={handleConfirmClose}
        setIsConfirm={setIsConfirm}
        type={t("integrations.smtp.smtpTemp")}
      />
      <SMTPAddModal
        isOpen={isCreateSmtpOpen}
        handleClose={handleCloseSmtp}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <SMTPEditModal
        isOpen={isEditSmtpOpen}
        handleClose={handleCloseEditSmtp}
        rowData={rowData}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </div>
  );
};

export default SMTPList;
const columnHelper = createColumnHelper<RowObj>();
