import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useToast,
} from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import { CreateSupportTicket } from "services/ticket";
import SaveConfirmModal from "./SaveConfirmModal";
import { useTranslation } from "react-i18next";

const AddTicketModal = ({ isOpen, handleClose, setRefresh, refresh }: any) => {
  const toast = useToast();
  const { t } = useTranslation();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const fileInputRef = useRef(null);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [isOpenWarning, setIsOpenWarning] = useState(false);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("supports.titleValid")),
    description: Yup.string().required(t("supports.descriptionValid")),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      let formData = new FormData();
      formData.append("title", values.title);
      formData.append("description", values.description);

      droppedFiles.forEach((file: File) => {
        formData.append(`file`, file);
      });

      CreateSupportTicket(formData)
        .then((response: any) => {
          if (response.success) {
            setRefresh(!refresh);
            toast({
              description: t("messages.supportTicketCreated"),
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            resetForm();
            setDroppedFiles([]);
            handleClose();
          } else {
            toast({
              description: response.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            throw new Error("creating Support Ticket failed");
          }
        })
        .catch((error: any) => {
          console.error("Error creating Support Ticket:", error);
        });
    },
  });

  const handleDrop = (e: any) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    setDroppedFiles([...droppedFiles, ...files]);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleRemoveFile = (indexToRemove: any) => {
    setDroppedFiles(
      droppedFiles.filter((file, index) => index !== indexToRemove)
    );
  };

  const handleFileChange = (e: any) => {
    const files = Array.from(e.target.files);
    setDroppedFiles([...droppedFiles, ...files]);
  };
  const checkIsDirtyForm = () => {
    let isDirty = formik.dirty && formik.submitCount === 0;
    if (isDirty || droppedFiles.length > 0) {
      openWarningModal();
    } else {
      handleClose();
      formik.resetForm();
      setDroppedFiles([]);
    }
  };
  const openWarningModal = () => {
    setIsOpenWarning(true);
  };
  const closeWarningModal = () => {
    setIsOpenWarning(false);
  };
  const onConfirm = () => {
    handleClose();
    formik.resetForm();
    setDroppedFiles([]);
    setIsOpenWarning(false);
  };
  const onCancel = () => {
    setIsOpenWarning(false);
  };
  return (
    <>
      <Drawer
        placement="right"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={checkIsDirtyForm}
        size={"md"}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent maxW={"600px"} className="dark:bg-navy-900">
          <DrawerHeader>{t("supports.addNew")}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody pb={6} onDrop={handleDrop} onDragOver={handleDragOver}>
            <FormControl>
              <FormLabel>{t("supports.title")}*</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="title"
                type="text"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
              />
              {formik.touched.title && formik.errors.title ? (
                <div className="text-red-500">
                  {formik.errors.title.toString()}
                </div>
              ) : null}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{t("supports.description")}*</FormLabel>
              <textarea
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="text-red-500">
                  {formik.errors.description.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("supports.attachments")}</FormLabel>
              <div
                className="mt-3 cursor-pointer rounded-md border-2 border-dashed border-gray-200 p-8"
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
                onClick={() => fileInputRef.current.click()}
              >
                <p className="text-center">{t("supports.dragDrop")}</p>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  multiple // Allow multiple file selection
                  className="hidden"
                />
              </div>
              {droppedFiles.length > 0 && (
                <div className="mt-3">
                  <p>{t("supports.filesUploaded")}:</p>
                  <ul>
                    {droppedFiles.map((file, index) => (
                      <li key={index} className="flex justify-between">
                        <span className="max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap">
                          {file.name}
                        </span>
                        <MdDelete
                          onClick={() => handleRemoveFile(index)}
                          className="h-6 w-6 cursor-pointer"
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </FormControl>
          </DrawerBody>

          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("save")}
            </Button>
            <Button
              onClick={() => {
                checkIsDirtyForm();
              }}
            >
              {t("cancel")}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <SaveConfirmModal
        isOpen={isOpenWarning}
        onClose={closeWarningModal}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </>
  );
};

export default AddTicketModal;
