/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { createAssistant } from "services/assitant";
import { Tooltip, useToast } from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import { getCampaignVoiceList } from "services/campaign";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getKnowledgeBaseList } from "services/knowledgeBase";
import { MdSettingsVoice } from "react-icons/md";
import { IoMdInformationCircle } from "react-icons/io";
import { usePrompt } from "hooks/usePrompt";
import { AiFillRobot } from "react-icons/ai";
import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import StepThree from "./stepThree";
import Card from "components/card";
import { useTranslation } from "react-i18next";
import OnboardingScreenModal from "components/modal/OnboardingScreenModal";
interface FormValues {
  type: string;
  name: string;
  language: string;
  goal: string;
  company_name: string;
  script: string;
  gender: string;
  voice_name: string;
  start_speech: string;
  voice_id: string;
  transfer_number: string;
  meeting_link: string;
  voice_note: string;
  maximum_time_per_call: number;
  isTransfer: boolean;
  action: boolean;
  knowledge_base_Id: string;
  recording: boolean;
  is_voice_note: boolean;
  is_back_sound: string;
  voice_speed: number;
  is_filler: boolean;
  fillers: string[];
  company_website: string;
  prefix: string;
  assistant_image: string;
  recorded_audio: string;
}
let allInitialValues: FormValues = {
  type: "outbound",
  name: "",
  language: "en",
  goal: "",
  company_name: "",
  script: "",
  gender: "",
  voice_name: "",
  start_speech: "Hello @[FirstName](firstName) , ",
  voice_id: "",
  transfer_number: "",
  meeting_link: "",
  voice_note: "",
  maximum_time_per_call: null,
  isTransfer: false,
  action: false,
  knowledge_base_Id: "",
  recording: true,
  is_voice_note: true,
  is_back_sound: "off",
  voice_speed: 1,
  is_filler: false,
  fillers: [],
  company_website: "",
  prefix: "https://",
  assistant_image: "",
  recorded_audio: "",
};

const AssistantCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [voiceList, setVoiceList] = useState([]);
  const [selectedGenderData, setSelectedGenderData] = useState(null);
  const [knowledgeBaseList, setKnowledgeBaseList] = useState([]);
  const [zapierData, setZapierData] = useState(null);
  const [actionData, setActionData] = useState([]);
  const [isRender, setIsRender] = useState(false);
  const [isOpenBooking, setIsOpenBooking] = useState(false);
  const [onbordingModalOpen, setOnbordingModalOpen] = useState(false);
  useEffect(() => {
    let steps = localStorage.getItem("steps");
    if (Number(steps) === 0) {
      setOnbordingModalOpen(true);
      localStorage.setItem("steps", "1");
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    let remainingData = JSON.parse(localStorage.getItem("assistantData"));
    if (remainingData) {
      formik.resetForm({
        values: remainingData,
      });

      setIsRender(!isRender);
      localStorage.removeItem("assistantData");
    }
    // return () => {
    //   formik.resetForm({
    //     values: allInitialValues,
    //   });
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveAction = (type: any) => {
    let deletedData = actionData.filter((item) => item.type !== type);
    setActionData(deletedData);
    if (type === "scheduleMeeting") {
      setBookingData({
        timezone: "Europe/Berlin",
        startDate: "",
        description: "",
        say: "",
        length: 30,
      });
    }
  };

  const validationSchema = Yup.object().shape({
    type: Yup.string().required(t("assistantForm.typeValid")),
    name: Yup.string().required(t("assistantForm.nameValid")),
    language: Yup.string().required(t("assistantForm.languageValid")),
    goal: Yup.string().required(t("assistantForm.goalValid")),
    company_name: Yup.string()
      .matches(/^[a-zA-Z0-9-_ ]*$/, t("assistantForm.companyNameValid2"))
      .required(t("assistantForm.companyNameValid")),
    script: Yup.string().required(t("assistantForm.scriptValid")),
    // start_speech: Yup.string()
    //   .required(t("assistantForm.callOpeningValid"))
    //   .max(255, t("assistantForm.callOpeningValid2")),
    is_voice_note: Yup.boolean(),
    voice_note: Yup.string().when(
      ["is_voice_note", "type"],
      ([is_voice_note, type], schema) => {
        if (is_voice_note && type === "outbound") {
          return Yup.string()
            .required(t("assistantForm.voiceNoteValid"))
            .max(255, t("assistantForm.voiceNoteValid2"));
        }
        return schema;
      }
    ),
    maximum_time_per_call: Yup.number().required(
      t("assistantForm.maximumDurationValid")
    ),
    gender: Yup.string().required(t("assistantForm.genderValid")),
    voice_name: Yup.string().required(t("assistantForm.accentValid")),
    isTransfer: Yup.boolean(),
    action: Yup.boolean(),
    is_filler: Yup.boolean(),
    fillers: Yup.array()
      .of(Yup.string())
      .when(["is_filler"], ([is_filler], schema) => {
        if (is_filler) {
          return Yup.array()
            .of(Yup.string())
            .min(1, "Fillers is required")
            .required("Fillers is required");
        }
        return schema;
      }),
    // company_website: Yup.string().url("assistantForm.companyWebsiteValid1"),
    company_website: Yup.string().test(
      "valid-url",
      "Invalid website URL",
      (value, context) => {
        const { prefix } = context.parent;
        if (!value) return true; // Allow empty values

        // Full URL is considered valid
        if (value.startsWith("http://") || value.startsWith("https://")) {
          try {
            new URL(value); // Attempt to create a URL object
            return true; // Valid URL
          } catch {
            return false; // Invalid URL
          }
        }
        // Stricter regex to validate URLs without http:// or https://
        const websiteRegex =
          /^(www\.)?([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#@!$&'()*+,;=]*)?$/;

        // Check if the value is a valid URL without protocol
        if (!websiteRegex.test(value)) {
          return false; // Valid URL without protocol
        }
        // Handle URL with prefix
        const fullUrl = `${prefix}${value}`;
        try {
          new URL(fullUrl); // Attempt to create a URL object
          return true; // Valid URL
        } catch {
          return false; // Invalid URL
        }
      }
    ),
    assistant_image: Yup.string().required(
      t("assistantForm.assistantImageValid")
    ),
    start_speech: Yup.string()
      .max(250, t("assistantForm.callOpeningValid2"))
      .test(
        "at-least-one-field-required",
        t("assistantForm.callOpeningValid"),
        function (value) {
          return !!value || !!this.parent.recorded_audio; // Validate that at least one is filled
        }
      ),
    recorded_audio: Yup.string().test(
      "at-least-one-field-required",
      t("assistantForm.voiceAudioValid"),
      function (value) {
        return !!value || !!this.parent.start_speech; // Validate that at least one is filled
      }
    ),
  });
  const transformActionData = (actions: any) => {
    return actions.map((action: any) => {
      if (action.type === "infoExtractor" && Array.isArray(action.questions)) {
        const transformedQuestions = action.questions.map((question: any) => {
          if (question.type === "openQuestion") {
            const { identifier, lookingFor, outputExamples, ...rest } =
              question;
            return {
              ...rest,
              identifier: identifier,
              question: lookingFor,
              options: outputExamples,
            };
          }
          return question;
        });
        return {
          ...action,
          questions: transformedQuestions,
        };
      }
      return action;
    });
  };
  const formik = useFormik({
    initialValues: allInitialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      let act: any = actionData?.length
        ? transformActionData(actionData)
        : null;
      let website = "";
      if (values?.company_website) {
        let fullWeb = values.company_website;
        if (fullWeb.startsWith("http://") || fullWeb.startsWith("https://")) {
          website = fullWeb;
        } else {
          const fullUrl = `${values.prefix}${fullWeb}`;
          website = fullUrl;
        }
      }

      const payload = {
        type: values?.type,
        name: values?.name,
        goal: values?.goal,
        company_name: values?.company_name,
        script: values?.script,
        actions: act,
        language: values.language,
        accent: "US",
        gender: values?.gender,
        voice_name: values?.voice_name,
        start_speech: values?.start_speech,
        voice_id: values?.voice_id,
        transfer_number: "",
        meeting_link: "",
        is_voice_note:
          values.type === "outbound" ? values?.is_voice_note : false,
        voice_note:
          values.type === "outbound"
            ? values?.is_voice_note
              ? values?.voice_note
              : ""
            : "",
        maximum_time_per_call: values?.maximum_time_per_call,
        knowledge_base_Id: values?.knowledge_base_Id
          ? values?.knowledge_base_Id
          : null,
        is_recording: values?.recording,
        zapier_hook: zapierData,
        is_back_sound: values?.is_back_sound === "off" ? false : true,
        voice_speed: values?.voice_speed,
        is_filler: values?.is_filler,
        fillers: values?.is_filler ? values?.fillers.join() : "",
        company_website: website,
        assistant_image: values?.assistant_image,
        recorded_audio: values?.recorded_audio,
      };
      handleUpdateAssistant(payload);
    },
  });
  useEffect(() => {
    getCampaignVoiceList()
      .then((response) => {
        if (response.success) {
          let voiceD = response?.data.sort((a: any, b: any) => {
            if (a.accent < b.accent) return -1;
            if (a.accent > b.accent) return 1;
            return 0;
          });
          setVoiceList(voiceD);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    getKnowledgeBaseList()
      .then((response: any) => {
        if (response.success) {
          setKnowledgeBaseList(response.data);
        }
      })
      .catch((error: string) => {
        console.error("Error :", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (selectedGenderData) {
      formik.setFieldValue("gender", selectedGenderData?.gender);
      formik.setFieldValue("voice_name", selectedGenderData?.name);
      formik.setFieldValue("voice_id", selectedGenderData?.id);
      formik.setFieldValue("voice_speed", 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGenderData]);

  const handleUpdateAssistant = (request: any) => {
    setLoading(true);
    createAssistant(request)
      .then((response) => {
        if (response.success) {
          setActionData([]);
          setZapierData(null);
          setTimeout(() => {
            toast({
              description: t("messages.assistantCreated"),
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            navigate("/admin/assistants");
            setLoading(false);
          }, 700);
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error :", error);
        setLoading(false);
      });
  };

  const [currentDate, setCurrentDate] = useState("");

  const [bookingData, setBookingData] = useState({
    timezone: "Europe/Berlin",
    startDate: "",
    description: "",
    say: "",
    length: 30,
  });

  useEffect(() => {
    if (isOpenBooking) {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      setCurrentDate(`${year}-${month}-${day}`);

      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const tomorrowYear = tomorrow.getFullYear();
      const tomorrowMonth = String(tomorrow.getMonth() + 1).padStart(2, "0");
      const tomorrowDay = String(tomorrow.getDate()).padStart(2, "0");

      setBookingData((prev: any) => ({
        ...prev,
        startDate: `${tomorrowYear}-${tomorrowMonth}-${tomorrowDay}`,
      }));
    }
  }, [isOpenBooking]);

  const isChangedActions = actionData.length > 0 ? actionData : null;
  const isChangedzapier = zapierData ? zapierData : null;
  let isDirty =
    (formik.dirty && formik.submitCount === 0) ||
    JSON.stringify(null) !== JSON.stringify(isChangedzapier) ||
    JSON.stringify(null) !== JSON.stringify(isChangedActions);
  let remainingDatas = JSON.parse(localStorage.getItem("assistantData"));
  usePrompt({
    isDirty: !remainingDatas ? isDirty : false,
  });

  let transferCallData = actionData.find(
    (item) => item.type === "transferCall"
  );
  let sendMessageData = actionData.find((item) => item.type === "sendMessage");
  let sendEmailData = actionData.find((item) => item.type === "sendMail");
  let infoExtractorData =
    actionData && actionData.find((item) => item.type === "infoExtractor");
  let questionsData = infoExtractorData?.questions
    ? infoExtractorData?.questions
    : [];
  let bookingSlotData =
    actionData && actionData.find((item) => item.type === "scheduleMeeting");
  let syncData = actionData && actionData.find((item) => item.type === "crm");

  const [currentStep, setCurrentStep] = useState(1);

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };
  const handleCloseOnbordingModal = () => {
    setOnbordingModalOpen(false);
  };
  return (
    <div
      className="h-full w-full rounded-xl sm:shadow-none"
      style={{
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 2px 4px",
      }}
    >
      {loading && <Loader />}

      <div className="flex w-full flex-col items-center justify-between gap-2 p-4 lg:flex-row">
        <span className="max-w-[240px] overflow-hidden text-ellipsis whitespace-nowrap text-[20px] font-bold dark:text-white">
          {t("assistantForm.createAssistant")}
        </span>
        <div className="flex flex-col gap-2 md:flex-row">
          <button
            onClick={() => navigate("/admin/assistants")}
            className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
          >
            <FaArrowLeft className="h-4 w-4" />
            {t("goBack")}
          </button>
        </div>
      </div>
      <div className={`p-1 pb-[24px] sm:p-4`}>
        <Card extra={"p-6 mb-16 max-w-[900px] m-auto"}>
          <ol className=" flex w-full items-center justify-center sm:space-x-8 sm:space-y-0 rtl:space-x-reverse">
            <Tooltip
              label={t("assistantForm.generalInformation")}
              placement="top"
            >
              <li
                className={`flex cursor-pointer items-center space-x-2.5 border-gray-200 pr-6 text-blue-700 dark:text-blue-700 sm:border-r-2 rtl:space-x-reverse`}
              >
                <span
                  className={`flex h-12 w-12 shrink-0 items-center justify-center rounded-full ${
                    currentStep === 1
                      ? "bg-blue-700 text-white dark:text-white"
                      : "bg-blue-100"
                  }`}
                >
                  <IoMdInformationCircle className="h-7 w-7" />
                </span>
                {currentStep === 1 && (
                  <span>
                    <p className="text-sm">{t("assistantForm.step1")}</p>
                    <h3 className="font-semibold leading-tight text-gray-800 dark:text-white">
                      {t("assistantForm.generalInformation")}
                    </h3>
                  </span>
                )}
              </li>
            </Tooltip>
            <Tooltip
              label={t("assistantForm.voiceConfiguration")}
              placement="top"
            >
              <li
                className={`flex cursor-pointer items-center space-x-2.5 border-gray-200 pr-6 text-blue-700 dark:text-blue-700 sm:border-r-2 rtl:space-x-reverse`}
              >
                <span
                  className={`flex h-12 w-12 shrink-0 items-center justify-center rounded-full ${
                    currentStep === 2
                      ? "bg-blue-700 text-white dark:text-white"
                      : "bg-blue-100"
                  }`}
                >
                  <MdSettingsVoice className="h-5 w-5" />
                </span>
                {currentStep === 2 && (
                  <span>
                    <p className="text-sm">{t("assistantForm.step2")}</p>
                    <h3 className="font-medium leading-tight text-gray-800 dark:text-white">
                      {t("assistantForm.voiceConfiguration")}
                    </h3>
                  </span>
                )}
              </li>
            </Tooltip>
            <Tooltip label={t("assistantForm.AIPrompt")} placement="top">
              <li
                className={`flex cursor-pointer items-center space-x-2.5 text-blue-700 dark:text-blue-700 rtl:space-x-reverse`}
              >
                <span
                  className={`flex h-12 w-12 shrink-0 items-center justify-center rounded-full ${
                    currentStep === 3
                      ? "bg-blue-700 text-white dark:text-white"
                      : "bg-blue-100"
                  }`}
                >
                  <AiFillRobot className="h-5 w-5" />
                </span>
                {currentStep === 3 && (
                  <span>
                    <p className="text-sm">{t("assistantForm.step3")}</p>
                    <h3 className="font-semibold leading-tight text-gray-800 dark:text-white">
                      {t("assistantForm.AIPrompt")}
                    </h3>
                  </span>
                )}
              </li>
            </Tooltip>
          </ol>
        </Card>

        {currentStep === 1 && (
          <StepOne formik={formik} handleNextStep={handleNextStep} />
        )}
        {currentStep === 2 && (
          <StepTwo
            formik={formik}
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep}
            voiceList={voiceList}
            selectedGenderData={selectedGenderData}
            setSelectedGenderData={setSelectedGenderData}
          />
        )}
        {currentStep === 3 && (
          <StepThree
            formik={formik}
            handlePrevStep={handlePrevStep}
            knowledgeBaseList={knowledgeBaseList}
            infoExtractorData={infoExtractorData}
            transferCallData={transferCallData}
            sendMessageData={sendMessageData}
            sendEmailData={sendEmailData}
            bookingSlotData={bookingSlotData}
            zapierData={zapierData}
            syncData={syncData}
            handleRemoveAction={handleRemoveAction}
            setZapierData={setZapierData}
            actionData={actionData}
            setActionData={setActionData}
            questionsData={questionsData}
            setBookingData={setBookingData}
            bookingData={bookingData}
            currentDate={currentDate}
            isOpenBooking={isOpenBooking}
            setIsOpenBooking={setIsOpenBooking}
          />
        )}
      </div>
      <OnboardingScreenModal
        onClose={handleCloseOnbordingModal}
        isOpen={onbordingModalOpen}
      />
    </div>
  );
};

export default AssistantCreate;
