import Swal from "sweetalert2";
import { t } from "i18next";
const openNotificationWithIcon = ({ message }: any) => {
  Swal.fire({
    title: "Error",
    text: message,
    icon: "error",
    confirmButtonText: "OK",
  }).then((result: any) => {
    // setIsModalOpen(false);
  });
};

export const errorHandler = (error: any) => {
  if (error.toJSON().message === "Network Error") {
    if (window.navigator.onLine) {
      // openNotificationWithIcon({
      //   message: t("messages.somethingWrong"),
      // });
      openNotificationWithIcon({
        message: error.response.data.message || error.message,
      });
    } else {
      openNotificationWithIcon({
        message: t("messages.checkInternet"),
      });
    }
  } else {
    if (error.response) {
      console.log(error);
      if (error.response.status === 500) {
        openNotificationWithIcon({
          message: error.response.data.message || error.message,
        });
      }
      if (error.response.status === 401) {
        openNotificationWithIcon({
          message: error.response.data.message || error.message,
        });
        setTimeout(() => {
          let themeChange = localStorage.getItem("isThemeChange");
          let themeMode = localStorage.getItem("themeMode");
          localStorage.clear();
          localStorage.setItem("isThemeChange", themeChange);
          localStorage.setItem("themeMode", themeMode);
          window.location.href = "/";
        }, 500);
      }
      if (error.response.status === 403) {
        openNotificationWithIcon({
          message: error.response.data.message || error.message,
        });
        setTimeout(() => {
          let themeChange = localStorage.getItem("isThemeChange");
          let themeMode = localStorage.getItem("themeMode");
          localStorage.clear();
          localStorage.setItem("isThemeChange", themeChange);
          localStorage.setItem("themeMode", themeMode);
          window.location.href = "/";
        }, 500);
      }
      if (error.response.status === 404) {
        openNotificationWithIcon({
          message: error.response.data.message || error.message,
        });
      }
      if (error.response.status === 400) {
        openNotificationWithIcon({
          message: error.response.data.message || error.message,
        });
      }
    } else {
      openNotificationWithIcon({
        message: error.response.data.message || error.message,
      });
    }
  }
};
