/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useColorMode,
} from "@chakra-ui/react";
import { createAudience, getContactList } from "services/contact";
import { useToast } from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import { convertToE164, isValidPhoneNumber } from "utils/utils";
import SaveConfirmModal from "./SaveConfirmModal";
import Loader from "components/Loader/Loader";
import TooltipHorizon from "components/tooltip";
import { FaPlusCircle } from "react-icons/fa";
import ContactAddModal from "./ContactAddModal";
import { useTranslation } from "react-i18next";
const AddAudianceModal = ({
  isOpen,
  handleClose,
  audienceId,
  setRefresh,
  refresh,
}: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { colorMode } = useColorMode();
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [contactData, setContactData] = useState([]);
  const [isOpenSegemntAddModal, setIsOpenSegemntAddModal] = useState(false);
  useEffect(() => {
    if (refresh && !audienceId) {
      fetchSegments();
    }
  }, [refresh, !audienceId]);
  useEffect(() => {
    if (isOpen && !audienceId) {
      fetchSegments();
      formik.setFieldValue("isAudiance", true);
    }
  }, [isOpen, !audienceId]);
  const fetchSegments = () => {
    setIsRequest(true);
    let param = {
      page: -1,
      perpage: 10,
      search: "",
    };
    getContactList(param)
      .then((response) => {
        setIsRequest(false);
        if (response.success) {
          if (response?.data) {
            setContactData(response?.data);
          } else {
            setContactData([]);
          }
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsRequest(false);
        console.error("Error:", error);
      });
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("audiences.fnameValidation")),
    lastName: Yup.string().required(t("audiences.lnameValidation")),
    contactNumber: Yup.string()
      .required(t("audiences.phoneValidation"))
      .test("valid-phone", t("audiences.phoneInvalidValidation"), (value) => {
        return isValidPhoneNumber(value);
      }),
    email: Yup.string()
      .required(t("requireEmail"))
      .email(t("audiences.emailValidation"))
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("audiences.emailValidation")),

    description: Yup.string().max(250, t("audiences.charValidation")),
    isAudiance: Yup.boolean(),
    segmentId: Yup.string().when(["isAudiance"], ([isAudiance], schema) => {
      if (isAudiance) {
        return Yup.string().required(t("audiences.segmentValidation"));
      }
      return schema;
    }),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      contactNumber: "",
      jobTitle: "",
      lifecycleStage: "",
      leadStatus: "",
      email: "",
      description: "",
      isAudiance: false,
      segmentId: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      const payload = {
        first_name: values?.firstName,
        last_name: values?.lastName,
        email: values?.email,
        phone: values?.contactNumber,
        description: values?.description,
        job_title: values?.jobTitle,
        lifecycle_stage: values?.lifecycleStage,
        lead_status: values?.leadStatus,
      };
      createAudience(payload, audienceId ? audienceId : values?.segmentId)
        .then((response: any) => {
          if (response.success) {
            setRefresh(!refresh);
            handleClose();
            toast({
              description: t("messages.contactCreated"),
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            resetForm();
          } else {
            toast({
              description: response.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.error("Error submitting feedback:", error);
        });
    },
  });
  const checkIsDirtyForm = () => {
    let isDirty = formik.dirty && formik.submitCount === 0;
    if (isDirty) {
      openWarningModal();
    } else {
      formik.resetForm();
      handleClose();
    }
  };
  const openWarningModal = () => {
    setIsOpenWarning(true);
  };
  const closeWarningModal = () => {
    setIsOpenWarning(false);
  };
  const onConfirm = () => {
    handleClose();
    formik.resetForm();
    setIsOpenWarning(false);
  };
  const onCancel = () => {
    setIsOpenWarning(false);
  };
  const openAddSegmentModal = () => {
    setIsOpenSegemntAddModal(true);
  };
  const closeAddSegmentModal = () => {
    setIsOpenSegemntAddModal(false);
  };
  return (
    <>
      <Drawer
        placement="right"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={checkIsDirtyForm}
        size={"xxl"}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        {isRequest && <Loader />}
        <DrawerContent maxW={"700px"} className="dark:bg-navy-900">
          <DrawerHeader>
            {t("add")} {audienceId ? "Audience" : "Contact"}
          </DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody pb={6}>
            <div className="flex justify-between gap-3">
              <FormControl>
                <FormLabel>{t("audiences.fName")}*</FormLabel>
                <input
                  className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                  id="firstName"
                  name="firstName"
                  type="text"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="text-red-500">
                    {formik.errors.firstName.toString()}
                  </div>
                ) : null}
              </FormControl>
              <FormControl>
                <FormLabel>{t("audiences.lName")}*</FormLabel>
                <input
                  className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                  id="lastName"
                  name="lastName"
                  type="text"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="text-red-500">
                    {formik.errors.lastName.toString()}
                  </div>
                ) : null}
              </FormControl>
            </div>

            <FormControl mt={4}>
              <FormLabel>{t("audiences.phone")}*</FormLabel>
              <PhoneInput
                inputStyle={{
                  background: colorMode === "dark" ? "#0B1437" : "#ffffff",
                  color: colorMode === "dark" ? "white" : "black",
                }}
                dropdownStyle={{
                  background: colorMode === "dark" ? "#0B1437" : "#ffffff",
                  color: colorMode === "dark" ? "white" : "black",
                }}
                inputProps={{
                  name: "contactNumber",
                  id: "contactNumber",
                  required: true,
                  className:
                    "pl-[50px] dark:bg-slate-900 inline-flex w-full border-[1px] items-center gap-x-2 rounded border border-gray-200 bg-white px-4 py-3 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-white focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900",
                }}
                buttonStyle={{
                  borderRadius: "8px",
                }}
                country={"us"}
                onChange={(value, country, event, formattedValue) => {
                  let myPhone = convertToE164(formattedValue);
                  formik.setFieldValue("contactNumber", myPhone);
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.contactNumber && formik.errors.contactNumber ? (
                <div className="text-red-500">
                  {formik.errors.contactNumber.toString()}
                </div>
              ) : null}
            </FormControl>
            {!audienceId && (
              <FormControl mt={4}>
                <FormLabel>{t("audiences.segment")}*</FormLabel>
                <div className="flex items-center gap-2">
                  <select
                    className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                    id="segmentId"
                    name="segmentId"
                    value={formik.values.segmentId}
                    onChange={formik.handleChange}
                  >
                    <option value="">{t("audiences.selectSegment")}</option>
                    {contactData &&
                      contactData.map((op, i) => (
                        <option key={i} value={op.id}>
                          {op.name}
                        </option>
                      ))}
                  </select>
                  <TooltipHorizon
                    trigger={
                      <button
                        onClick={() => openAddSegmentModal()}
                        className="flex items-center justify-center gap-1 rounded bg-blue-700 px-3 py-2 text-white hover:bg-blue-800"
                      >
                        <FaPlusCircle className="h-4 w-4" />
                      </button>
                    }
                    placement="top"
                    content={t("segments.addSegment")}
                    extra="rounded text-[11px]"
                  />
                </div>
                {formik.touched.segmentId && formik.errors.segmentId ? (
                  <div className="text-red-500">
                    {formik.errors.segmentId.toString()}
                  </div>
                ) : null}
              </FormControl>
            )}
            <FormControl mt={4}>
              <FormLabel>{t("audiences.email")} *</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="email"
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500">
                  {formik.errors.email.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("audiences.jobTitle")}</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="jobTitle"
                name="jobTitle"
                type="text"
                value={formik.values.jobTitle}
                onChange={formik.handleChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("audiences.lifecycle")}</FormLabel>
              <select
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="lifecycleStage"
                name="lifecycleStage"
                value={formik.values.lifecycleStage}
                onChange={formik.handleChange}
              >
                <option value="">{t("audiences.selectStage")}</option>
                <option value="Subscriber">
                  {t("audiences.stageOption1")}
                </option>
                <option value="Lead">{t("audiences.stageOption2")}</option>
                <option value="Marketing Qualified Lead">
                  {t("audiences.stageOption3")}
                </option>
                <option value="Sales Qualified Lead">
                  {t("audiences.stageOption4")}
                </option>
                <option value="Opportunity">
                  {t("audiences.stageOption5")}
                </option>
                <option value="Customer">{t("audiences.stageOption6")}</option>
                <option value="Evangelist">
                  {t("audiences.stageOption7")}
                </option>
                <option value="Other">{t("audiences.stageOption8")}</option>
              </select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("audiences.leadStatus")}</FormLabel>
              <select
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="leadStatus"
                name="leadStatus"
                value={formik.values.leadStatus}
                onChange={formik.handleChange}
              >
                <option value="">{t("audiences.leadSelect")}</option>
                <option value="New">{t("audiences.leadOption1")}</option>
                <option value="Open">{t("audiences.leadOption2")}</option>
                <option value="In Progress">
                  {t("audiences.leadOption3")}
                </option>
                <option value="Open Deal">{t("audiences.leadOption4")}</option>
                <option value="Unqualified">
                  {t("audiences.leadOption5")}
                </option>
                <option value="Uttempted to contact">
                  {t("audiences.leadOption6")}
                </option>
                <option value="Connected">{t("audiences.leadOption7")}</option>
                <option value="Bad timing">{t("audiences.leadOption8")}</option>
              </select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("audiences.description")}</FormLabel>
              <textarea
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="description"
                name="description"
                rows={3}
                value={formik.values.description}
                onChange={(e) => {
                  if (e.target.value.length <= 250) {
                    formik.setFieldValue(
                      "description",
                      e.target.value.slice(0, 250)
                    );
                  }
                }}
              ></textarea>
              <div>
                <div className="text-right text-[14px] text-gray-700">
                  {formik.values.description.length}/250{" "}
                  {t("audiences.characters")}
                </div>
              </div>
            </FormControl>
          </DrawerBody>

          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <Button
              colorScheme="blue"
              type="submit"
              mr={3}
              onClick={() => formik.handleSubmit()}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("save")}
            </Button>
            <Button
              onClick={() => {
                checkIsDirtyForm();
              }}
            >
              {t("cancel")}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <SaveConfirmModal
        isOpen={isOpenWarning}
        onClose={closeWarningModal}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
      <ContactAddModal
        isOpen={isOpenSegemntAddModal}
        handleClose={closeAddSegmentModal}
        setContactData={setContactData}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </>
  );
};

export default AddAudianceModal;
