// JoyrideContext.tsx
import React, { useState, useEffect } from "react";
import Joyride, { CallBackProps, Step } from "react-joyride";
import { useLocation, useNavigate } from "react-router-dom";

export const JoyrideProvider = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [runTour, setRunTour] = useState<boolean>(false);
  const [allSteps] = useState<Step[]>([
    {
      target: ".first-step",
      content: (
        <>
          <h3 className="md:text-32x text-w-500 mb-4 text-2xl font-medium md:leading-[42px]">
            Manage Assistants
          </h3>
          <p>Oversee virtual Assistants to keep tasks running smoothly.</p>
        </>
      ),
      placement: "bottom",
      data: {
        previous: "/admin/assistants",
        next: "/admin/dialers",
      },
    },
    {
      target: ".second-step",
      content: (
        <>
          <h3 className="md:text-32x text-w-500 mb-4 text-2xl font-medium md:leading-[42px]">
            Set Up Dialers
          </h3>
          <p>Set up phone systems for easy calls to clients.</p>
        </>
      ),
      placement: "bottom",
      data: {
        previous: "/admin/dialers",
        next: "/admin/price",
      },
    },
    {
      target: ".third-step",
      content: (
        <>
          <h3 className="md:text-32x text-w-500 mb-4 text-2xl font-medium md:leading-[42px]">
            Handle Billing
          </h3>
          <p>Take care of invoices and payments to keep finances on track.</p>
        </>
      ),
      placement: "bottom",
      data: {
        previous: "/admin/price",
        next: "/admin/segments",
      },
    },
    {
      target: ".fourth-step",
      content: (
        <>
          <h3 className="md:text-32x text-w-500 mb-4 text-2xl font-medium md:leading-[42px]">
            Define Segments
          </h3>
          <p>Group audiences for better-targeted marketing.</p>
        </>
      ),
      placement: "bottom",
      data: {
        previous: "/admin/segments",
        next: "/admin/contacts",
      },
    },
    {
      target: ".fifth-step",
      content: (
        <>
          <h3 className="md:text-32x text-w-500 mb-4 text-2xl font-medium md:leading-[42px]">
            Organize Contacts
          </h3>
          <p>Keep a tidy list of contacts for easy access.</p>
        </>
      ),
      placement: "bottom",
      data: {
        previous: "/admin/contacts",
        next: "/admin/companies",
      },
    },
    {
      target: ".sixth-step",
      content: (
        <>
          <h3 className="md:text-32x text-w-500 mb-4 text-2xl font-medium md:leading-[42px]">
            Track Companies
          </h3>
          <p>Watch company information to find new leads.</p>
        </>
      ),
      placement: "bottom",
      data: {
        previous: "/admin/companies",
        next: "/admin/deals",
      },
    },
    {
      target: ".seventh-step",
      content: (
        <>
          <h3 className="md:text-32x text-w-500 mb-4 text-2xl font-medium md:leading-[42px]">
            Manage Deals
          </h3>
          <p>Keep track of sales and deals to close more successfully.</p>
        </>
      ),
      placement: "bottom",
      data: {
        previous: "/admin/deals",
        next: "/admin/call-playground",
      },
    },
    {
      target: ".eighth-step",
      content: (
        <>
          <h3 className="md:text-32x text-w-500 mb-4 text-2xl font-medium md:leading-[42px]">
            Explore Playground
          </h3>
          <p>Test new features in a safe environment.</p>
        </>
      ),
      data: {
        previous: "/admin/call-playground",
        next: "/admin/campaigns",
      },
    },
    {
      target: ".ningth-step",
      content: (
        <>
          <h3 className="md:text-32x text-w-500 mb-4 text-2xl font-medium md:leading-[42px]">
            Create Campaigns
          </h3>
          <p>Launch marketing efforts to attract and convert customers.</p>
        </>
      ),
      placement: "bottom",
      data: {
        previous: "/admin/campaigns",
        next: "/admin/call-logs",
      },
    },
    {
      target: ".tenth-step",
      content: (
        <>
          <h3 className="md:text-32x text-w-500 mb-4 text-2xl font-medium md:leading-[42px]">
            Review Call Logs
          </h3>
          <p>Check call records to see what can be improved.</p>
        </>
      ),
      placement: "bottom",
      data: {
        previous: "/admin/call-logs",
        next: "/admin/support",
      },
    },
    {
      target: ".eleventh-step",
      content: (
        <>
          <h3 className="md:text-32x text-w-500 mb-4 text-2xl font-medium md:leading-[42px]">
            Get Support
          </h3>
          <p>Reach out for help with any questions or issues.</p>
        </>
      ),
      placement: "bottom",
      data: {
        previous: "/admin/call-logs",
        next: "/admin/dashboard",
      },
    },
  ]);

  //   const startTour = () => {
  //     setRunTour(true);
  //   };

  const stopTour = () => {
    setRunTour(false);
    // localStorage.setItem("steps", "1");
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    console.log(data);
    const {
      action,
      index,
      step: {
        data: { next, previous },
      },
    } = data;
    console.log(next, previous);
    const isPreviousAction = action === "prev";
    // if (status === "finished" || status === "skipped") {
    //   stopTour();
    //   return;
    // }
    // if (type === "step:after") {
    //   console.log(index);
    //   if (index === 0) {
    //     navigate("/admin/dialers");
    //   }
    //   if (index === 1) {
    //     navigate("/admin/price");
    //   }
    // }
    if (index < 10) {
      //   stopTour();

      navigate(isPreviousAction && previous ? previous : next);
      return;
    }

    if (index === 10) {
      if (isPreviousAction && previous) {
        //   setState({ run: false });
        stopTour();
        // navigate(previous);
      } else {
        //   setState({ run: false, stepIndex: 0, tourActive: false });
      }
    }
    // if (action === "next") {
    //   // Handle navigation based on the step index
    //   switch (index) {
    //     case 0:
    //       navigate("/admin/assistants");
    //       break;
    //     case 1:
    //       navigate("/admin/dialers");
    //       break;
    //     case 2:
    //       navigate("/admin/price");
    //       break;
    //     case 3:
    //       navigate("/admin/segments");
    //       break;
    //     case 4:
    //       navigate("/admin/contacts");
    //       break;
    //     case 5:
    //       navigate("/admin/companies");
    //       break;
    //     case 6:
    //       navigate("/admin/deals");
    //       break;
    //     case 7:
    //       navigate("/admin/call-playground");
    //       break;
    //     case 8:
    //       navigate("/admin/campaigns");
    //       break;
    //     case 9:
    //       navigate("/admin/call-logs");
    //       break;
    //     case 10:
    //       navigate("/admin/support");
    //       break;
    //     default:
    //       break;
    //   }
    // }
  };
  useEffect(() => {
    if (localStorage.getItem("steps") === "2") {
      if (
        location.pathname === "/" ||
        location.pathname === "/admin/dashboard"
      ) {
        // Redirect to the desired page and start the tour
        navigate("/admin/assistants");
        setTimeout(() => setRunTour(true), 100); // Adjust delay as needed
      }
    }
  }, [location, navigate]);
  return (
    <>
      {runTour && (
        <Joyride
          steps={allSteps}
          run={runTour}
          continuous
          showSkipButton
          callback={handleJoyrideCallback}
          styles={{
            options: {
              zIndex: 10000, // Ensure it appears above other content
            },
          }}
        />
      )}
    </>
  );
};
