import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { GrEdit } from "react-icons/gr";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import { updateKnowledgeBase } from "services/knowledgeBase";
import { useToast } from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import AddEditknowlageBaseModal from "components/modal/AddEditknowlageBaseModal";
import EditknowlageBaseModal from "components/modal/EditknowlageBaseModal";
import { usePrompt } from "hooks/usePrompt";
import { Trans, useTranslation } from "react-i18next";
interface FormValues {
  title: string;
  data: Array<any>;
}
let allInitialValues: FormValues = {
  title: "",
  data: [],
};
const KnowledgeBaseUpdate = () => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const location = useLocation();
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const formData = location.state?.formData;
  const [loading, setLoading] = useState(false);
  const [isKnowladgeBaseModal, setIsKnowladgeBaseModal] = useState(false);
  const [isEditKnowladgeBaseModal, setIsEditKnowladgeBaseModal] =
    useState(false);
  const [isRemoveCardModal, setIsRemoveCardModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [cardIndex, setCardIndex] = useState(null);
  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("knowledgeBase.titleValid")),
    data: Yup.array()
      .of(
        Yup.object().shape({
          question: Yup.string().required(t("knowledgeBase.questionValid")),
          // .max(250, "Question must be 250 characters or less"),
          answer: Yup.string().required(t("knowledgeBase.ansValid")),
          // .max(250, "Answer must be 250 characters or less"),
        })
      )
      .min(1, t("knowledgeBase.questionAnsValid")),
  });

  const formik = useFormik({
    initialValues: allInitialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      const payload = {
        title: values?.title,
        data: values?.data,
      };
      handleUpdateKnowledgeBase(payload);
    },
  });
  useEffect(() => {
    if (formData) {
      allInitialValues.title = formData?.title || "";
      allInitialValues.data = formData?.data || [];
      formik.setValues({
        title: formData?.title || "",
        data: formData?.data || [],
      });
      localStorage.setItem(
        "knowladgeData",
        JSON.stringify({
          title: formData?.title || "",
          data: formData?.data || [],
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleUpdateKnowledgeBase = (request: any) => {
    setLoading(true);
    updateKnowledgeBase(request, formData?.hash_id)
      .then((response) => {
        if (response.success) {
          localStorage.setItem(
            "knowladgeData",
            JSON.stringify({
              title: request?.title,
              data: request?.data,
            })
          );
          toast({
            description: t("messages.knowBaseUpdated"),
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          setLoading(false);
          setTimeout(() => {
            navigate("/admin/knowledge-base");
          }, 500);
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error :", error);
        setLoading(false);
      });
  };
  const openKnowlageBaseModal = () => {
    setIsKnowladgeBaseModal(true);
  };
  const closeKnowlageBaseModal = () => {
    setIsKnowladgeBaseModal(false);
  };
  const handleRemoveCloseCard = () => {
    setIsRemoveCardModal(false);
  };
  const handleRemoveCard = () => {
    let allQues = formik.values.data;
    allQues.splice(cardIndex, 1);
    formik.setFieldValue("data", allQues);
    setIsRemoveCardModal(false);
  };
  const handleOpenRemoveCard = (index: any) => {
    setCardIndex(index);
    setIsRemoveCardModal(true);
  };
  const handleOpenEditCard = (index: any) => {
    setCardIndex(index);
    setIsEditModal(true);
    setIsEditKnowladgeBaseModal(true);
  };
  const closeEditKnowlageBaseModal = () => {
    setIsEditKnowladgeBaseModal(false);
  };
  let orgData = localStorage.getItem("knowladgeData");
  let isDirty = orgData !== JSON.stringify(formik?.values);
  usePrompt({
    isDirty,
  });
  return (
    <div
      className="h-full w-full rounded-xl"
      style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 2px 4px" }}
    >
      {loading && <Loader />}

      <div className="flex w-full flex-col items-center justify-between gap-2 border-b-[1px] border-[#d3d3d3] p-4 lg:flex-row">
        <span className="max-w-[240px] overflow-hidden text-ellipsis whitespace-nowrap text-[20px] font-bold dark:text-white">
          {t("knowledgeBase.updateKnowledgeBase")}
        </span>
        <div className="flex flex-col gap-2 md:flex-row">
          <button
            onClick={() => navigate("/admin/knowledge-base")}
            className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
          >
            <FaArrowLeft className="h-4 w-4" />
            {t("goBack")}
          </button>
        </div>
      </div>
      <div className={`p-4 pb-[24px]`}>
        <div>
          <div className="mt-3 flex flex-col gap-6 md:flex-row">
            <div className="relative flex w-full flex-col gap-1">
              <label className="  dark:text-white" htmlFor="name ">
                {t("knowledgeBase.title")} *
              </label>

              <input
                id="title"
                type="text"
                placeholder={t("knowledgeBase.enterTitle")}
                value={formik?.values.title}
                className="h-[50px] rounded border border-gray-500 pl-4  
          focus:border-blue-400 focus:outline-none focus:ring-blue-700 dark:bg-navy-900 dark:hover:border-[#fff]"
                onChange={(e) => formik.setFieldValue("title", e.target.value)}
              />
              {formik.touched.title && formik.errors.title ? (
                <div className="text-red-500">
                  {formik.errors.title.toString()}
                </div>
              ) : null}
            </div>
          </div>
          <div className="relative mt-6 flex w-full flex-col gap-1">
            <div className="grid gap-6 md:grid-cols-2 md:gap-12 lg:grid-cols-3 xl:grid-cols-4">
              <div
                className="flex w-full cursor-pointer flex-col items-center justify-center gap-4 rounded-lg border-2 border-dashed border-gray-600 p-2 text-gray-400 hover:bg-gray-100 dark:hover:bg-navy-900 md:h-[200px]"
                onClick={() => openKnowlageBaseModal()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="h-12 w-12 text-gray-400"
                >
                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                  <polyline points="17 8 12 3 7 8"></polyline>
                  <line x1="12" x2="12" y1="3" y2="15"></line>
                </svg>
                {t("knowledgeBase.addKnowledgeBase")}
              </div>
              {formik.values.data &&
                formik.values.data?.map((op: any, i: any) => (
                  <div
                    key={i}
                    className="bg-card text-card-foreground w-full rounded-lg border shadow-md hover:bg-gray-50 dark:hover:bg-navy-900"
                  >
                    <div
                      className="flex h-16 cursor-pointer flex-col space-y-1.5 p-6 px-4 pb-0 pt-4 text-sm font-bold"
                      title={op.question}
                    >
                      <div className="line-clamp-2">{op.question}</div>
                    </div>
                    <div
                      className="line-clamp-3 h-[58px] cursor-pointer p-6 px-4 pt-0 text-sm"
                      title={op.answer}
                    >
                      {op.answer}
                    </div>
                    <div className="flex items-center gap-4 p-6 px-4 pb-4 pt-6">
                      <button onClick={() => handleOpenEditCard(i)}>
                        <GrEdit className="text-[22px]" />
                      </button>
                      <button onClick={() => handleOpenRemoveCard(i)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          className="h-6 w-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          ></path>
                        </svg>
                      </button>
                      <div className="h-8 flex-grow cursor-pointer"></div>
                    </div>
                  </div>
                ))}
              <div className="h-12"></div>
            </div>

            {formik.touched.data && formik.errors.data ? (
              <div className="text-red-500">
                {formik.errors.data.toString()}
              </div>
            ) : null}
          </div>
          <div className="mt-5 flex justify-center gap-[20px]">
            <button
              onClick={() => formik.handleSubmit()}
              className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
              type="submit"
            >
              {t("save")}
            </button>
          </div>
          <AddEditknowlageBaseModal
            handleClose={closeKnowlageBaseModal}
            isOpen={isKnowladgeBaseModal}
            questionsAnswers={formik.values.data}
            formikSet={formik}
          />
          <EditknowlageBaseModal
            handleClose={closeEditKnowlageBaseModal}
            isOpen={isEditKnowladgeBaseModal}
            isEdit={isEditModal}
            setIsEditModal={setIsEditModal}
            cardIndex={cardIndex}
            setCardIndex={setCardIndex}
            questionsAnswers={formik.values.data}
            formikSet={formik}
          />
          <Modal
            isCentered
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isRemoveCardModal}
            onClose={handleRemoveCloseCard}
            size={"xl"}
            scrollBehavior={"inside"}
            blockScrollOnMount={false}
          >
            <ModalOverlay />
            <ModalContent className="dark:bg-navy-900">
              <ModalCloseButton />
              <ModalBody pb={6}>
                <div className="mt-6 flex flex-col items-center justify-center">
                  <h1 className="mb-8 mt-4 text-4xl">
                    {t("knowledgeBase.deleteKnowledgeCard.title")}
                  </h1>
                  <p className="mb-4 text-[#000000]  dark:text-white">
                    <Trans i18nKey="knowledgeBase.deleteKnowledgeCard.warning">
                      This action cannot be undone. This will permanently delete
                      this <strong>knowledge card</strong> from our servers.
                    </Trans>
                  </p>
                </div>
              </ModalBody>

              <ModalFooter>
                <Button
                  onClick={() => {
                    handleRemoveCard();
                  }}
                  colorScheme="red"
                  mr={3}
                  className="dark:bg-red-500-700 dark:text-white"
                >
                  {t("knowledgeBase.deleteKnowledgeCard.removeCard")}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default KnowledgeBaseUpdate;
