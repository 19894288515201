/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import Card from "components/card";
import { MdPermContactCalendar } from "react-icons/md";
import { BsTelephoneForwardFill } from "react-icons/bs";
import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  getPlayground,
  playGroundCall,
  playGroundFavourite,
} from "services/playground";
import { Tag, useToast } from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import PlayGroundDetailModal from "components/modal/PlayGroundDetailModal";
import CallDurationTotal from "components/callDurationTotal";
import { formatDateAmPm, normalizeInput } from "utils/utils";
import { IoMdSync } from "react-icons/io";
import ConfirmRedailCallModal from "components/modal/ConfirmRedialCallModal";
import TooltipHorizon from "components/tooltip";
import { FaFlag, FaRegStar, FaStar } from "react-icons/fa";
import PlaygroundFeedBackModal from "components/modal/PlaygroundFeedBackModal";
import WavePlayer from "components/waveSurferPlayer/WaveSurferPlayer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type RowObj = {
  answered_by: string;
  phone_number: string;
  start_speech: string;
  prompt: string;
  transfer_number: any;
  call_duration_in_sec: any;
  recording_url: any;
  action: string;
  call_sentiment: string;
  created_at: string;
  status: string;
  call_sid: string;
  call_status: any;
  maximum_time_per_call: any;
  actions: any;
  is_call_transferred: any;
  is_message_sent: any;
  feedback: any;
  id: any;
  is_voice_note: any;
  newStatus: any;
  is_fav: any;
  is_whatsapp_sent: any;
  is_meeting_scheduled: any;
  is_email_sent: any;
};

function PlayGroundHistory() {
  const audioUrl = process.env.REACT_APP_URL_FOR_AUDIO;
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isRequest, setIsRequest] = useState(true);
  const [tableData, setTableData] = useState<RowObj[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [playGroundData, setPlayGroundData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [isOpenRedailModal, setIsOpenRedailModal] = useState(false);
  const [isRedialConfirm, setIsRedialConfirm] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [sentiment, setSentiment] = useState("");
  const [callStatus, setCallStatus] = useState("");
  const [favourite, setFavourite] = useState("");
  const [callId, setCallId] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState<number | null>(
    null
  );
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const fetchData = useCallback(() => {
    const params = {
      page: pagination?.pageIndex,
      perpage: pagination?.pageSize,
      status: callStatus,
      sentiment: sentiment,
      favourite: favourite,
    };
    fetchPlaygroundHistory(params);
  }, [
    pagination?.pageIndex,
    pagination?.pageSize,
    callStatus,
    sentiment,
    favourite,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFeedbackOpen = (id: any) => {
    setFeedbackModalOpen(true);
    setCallId(id);
  };
  const handleFeedbackClose = () => {
    setFeedbackModalOpen(false);
  };
  const filterData = (criteria: any, value: any) => {
    if (criteria === "callStatus") {
      setCallStatus(value);
    } else if (criteria === "sentiment") {
      setSentiment(value);
    } else {
      setFavourite(value);
    }
  };

  useEffect(() => {
    if (refresh) {
      const params = {
        page: pagination?.pageIndex,
        perpage: pagination?.pageSize,
        status: callStatus,
        sentiment: sentiment,
        favourite: favourite,
      };
      fetchPlaygroundHistory(params);
    }
  }, [refresh]);
  const fetchPlaygroundHistory = (params: any) => {
    getPlayground(params)
      .then((response) => {
        setIsRequest(false);
        if (response.success) {
          if (response?.data) {
            for (let item of response?.data) {
              if (
                item?.answered_by !== "robot" &&
                item?.call_sid &&
                item?.call_status &&
                Number(item?.call_duration_in_sec) > 0
              ) {
                item.newStatus = "Completed";
              }

              if (
                item?.answered_by !== "robot" &&
                item?.call_sid &&
                !item?.call_status &&
                Number(item?.call_duration_in_sec) === 0
              ) {
                if (
                  timeStatus(
                    item?.created_at,
                    Number(item?.maximum_time_per_call)
                  )
                ) {
                  item.newStatus = "In Progress";
                } else {
                  item.newStatus = "Failed";
                }
              }
              if (item.answered_by === "robot" && !item?.is_voice_note) {
                item.newStatus = "Failed";
              }
              if (item.answered_by === "robot" && item?.is_voice_note) {
                item.newStatus = "Voice Note Sent";
              }
            }
            setTotalPages(response?.totalPages || 0);
          }
          setTableData(response?.data);
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsRequest(false);
        setRefresh(!refresh);
      });
  };
  useEffect(() => {
    if (isRedialConfirm === true && playGroundData) {
      const payload = {
        first_name: playGroundData?.first_name,
        last_name: playGroundData?.last_name,
        email: playGroundData?.email,
        assistant_id: playGroundData?.assistant_id,
        phone_number: playGroundData?.phone_number.split("\n"),
        dialer_id: playGroundData?.dialer_id,
      };
      playGroundCall(payload)
        .then((response) => {
          setIsRequest(false);
          setPlayGroundData(null);
          setIsRedialConfirm(false);
          if (response?.success) {
            if (response?.upgrade) {
              navigate("/admin/price");
              toast({
                description: t("messages.dailyLimitReached"),
                status: "info",
                duration: 8000,
                isClosable: true,
                position: "top-left",
              });
              setPlayGroundData(null);
              setIsRedialConfirm(false);
              setRefresh(!refresh);
              return;
            }
            setPlayGroundData(null);
            setIsRedialConfirm(false);
            setRefresh(!refresh);
            toast({
              description: t("messages.playgroundSubmit"),
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          } else {
            toast({
              description: response.message,
              status: "error",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRedialConfirm]);
  const timeStatus = (created_at: string, time_per_call: number) => {
    const createdAtDate = new Date(created_at);

    const calculatedTime = new Date(
      createdAtDate.getTime() + time_per_call * 60000
    );
    const currentTime = new Date();

    return currentTime.getTime() < calculatedTime.getTime();
  };
  const redialCall = (item: any) => {
    setPlayGroundData(item);
    setIsOpenRedailModal(true);
  };

  const columns = [
    columnHelper.accessor("phone_number", {
      id: "Phone Number",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("playground.phone")}
        </p>
      ),
    }),
    columnHelper.accessor("call_duration_in_sec", {
      id: "Duration",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("playground.duration")}
        </p>
      ),
    }),
    columnHelper.accessor("created_at", {
      id: "Called At",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("playground.created")}
        </p>
      ),
    }),
    columnHelper.accessor("recording_url", {
      id: "Recording",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("playground.recording")}
        </p>
      ),
    }),
    columnHelper.accessor("call_sentiment", {
      id: "Sentiments",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("playground.sentiments")}
        </p>
      ),
    }),
    columnHelper.accessor("status", {
      id: "Call Status",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("playground.callStatus")}
        </p>
      ),
    }),
    columnHelper.accessor("actions", {
      id: "Actions",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("playground.actions")}
        </p>
      ),
    }),
    columnHelper.accessor("feedback", {
      id: "Feedback",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("playground.feedback")}
        </p>
      ),
    }),
    columnHelper.accessor("is_fav", {
      id: "Favorite",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white"></p>
      ),
    }),
  ];

  const table = useReactTable({
    data: tableData,
    columns: columns,
    state: {
      pagination,
      columnFilters: [
        { id: "status", value: callStatus },
        { id: "sentiment", value: sentiment },
      ],
    },
    pageCount: totalPages,
    manualPagination: true,
    manualFiltering: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });
  const handleOpenModal = (data: any) => {
    setPlayGroundData(data);
    setIsOpenModal(true);
  };
  const handleCloseModal = () => {
    setIsOpenModal(!isOpenModal);
  };
  const handleCloseRedailModal = () => {
    setIsOpenRedailModal(false);
  };
  const doFavourite = (item: any) => {
    let req = {
      id: item.id,
      is_fav: !item.is_fav,
    };
    setIsRequest(true);
    playGroundFavourite(req)
      .then((response) => {
        setIsRequest(false);
        if (response.success) {
          toast({
            description: item.is_fav
              ? t("messages.UNMARKED_FAV")
              : t("messages.MARKED_FAV"),
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        } else {
          toast({
            description: response?.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsRequest(false);
      });
  };
  return (
    <div>
      {isRequest && <Loader />}
      <div className="mt-2 flex flex-wrap justify-end gap-4 py-2 md:flex-nowrap">
        <select
          value={sentiment}
          onChange={(e) => filterData("sentiment", e.target.value)}
          className="w-fit rounded border-[1px] border-gray-200 px-4 
  py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
        >
          <option value="">{t("playground.allSentiments")}</option>
          <option value={"positive"}>{t("playground.positive")}</option>
          <option value={"negative"}>{t("playground.negative")}</option>
          <option value={"neutral"}>{t("playground.neutral")}</option>
        </select>
        <select
          value={callStatus}
          onChange={(e) => filterData("callStatus", e.target.value)}
          className="w-fit rounded border-[1px] border-gray-200 px-4 
  py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
        >
          <option value="">{t("playground.allCallStatus")}</option>
          <option value={"completed"}>{t("playground.completed")}</option>
          <option value={"inProgress"}>{t("playground.inProgress")}</option>
          <option value={"failed"}>{t("playground.failed")}</option>
        </select>
        <select
          value={favourite}
          onChange={(e) => filterData("favourite", e.target.value)}
          className="w-fit rounded border-[1px] border-gray-200 px-4 
  py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
        >
          <option value="">{t("playground.all")}</option>
          <option value={"true"}>{t("playground.favourite")}</option>
          <option value={"false"}>{t("playground.nonFavourite")}</option>
        </select>
        <button
          onClick={() => {
            setRefresh(!refresh);
            setIsRequest(true);

            if (Array.isArray((window as any).dataLayer)) {
              (window as any).dataLayer.push({
                event: "Refresh",
                buttonName: "Refresh",
              });
            }
          }}
          className="flex items-center justify-center gap-2 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
        >
          <IoMdSync className={`h-5 w-5 ${isRequest && "animate-spin"} `} />
          {t("playground.refresh")}
        </button>
      </div>
      {tableData && tableData?.length === 0 ? (
        <Card extra={"w-full sm:overflow-auto px-6"}>
          <div className="mb-auto flex flex-col items-center justify-center">
            <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
              <MdPermContactCalendar />
            </div>
            <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
              {t("playground.noPlayground")}
            </h4>
          </div>
        </Card>
      ) : (
        <Card extra={"w-full sm:overflow-auto px-6"}>
          <div className="mt-1 overflow-x-auto">
            <table className="responsive-table w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className="!border-px !border-gray-400"
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        scope="col"
                        className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                      >
                        <div className="items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows &&
                  table.getRowModel().rows.map((op, i) => (
                    <tr key={op.index}>
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={"Phone Number"}
                      >
                        <p className="flex cursor-pointer items-center justify-end gap-2 whitespace-nowrap text-blue-700 hover:underline sm:justify-center">
                          <span onClick={() => handleOpenModal(op?.original)}>
                            {normalizeInput(op?.original?.phone_number)}
                          </span>
                          <TooltipHorizon
                            trigger={
                              <span onClick={() => redialCall(op?.original)}>
                                <BsTelephoneForwardFill />
                              </span>
                            }
                            placement="bottom"
                            content={"Redial Call"}
                            extra="rounded text-[11px]"
                          />
                        </p>
                      </td>
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={"Call Duration"}
                      >
                        <p className="flex items-center justify-end gap-2 text-center sm:justify-center">
                          <span>
                            {op?.original.answered_by !== "robot" &&
                            op?.original?.newStatus !== "Failed" ? (
                              <CallDurationTotal
                                totalDurationInSeconds={Number(
                                  op?.original?.call_duration_in_sec || 0
                                )}
                              />
                            ) : (
                              <CallDurationTotal
                                totalDurationInSeconds={Number(0)}
                              />
                            )}
                          </span>
                        </p>
                      </td>
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={"Called At"}
                      >
                        <p className="flex min-w-[200px] items-center justify-end gap-2 sm:justify-center">
                          {op?.original.created_at
                            ? formatDateAmPm(op?.original.created_at)
                            : "-"}
                        </p>
                      </td>
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={"Call Recording"}
                      >
                        <div className="min-w-[200px] text-center">
                          {op?.original?.recording_url ? (
                            <>
                              {op?.original?.answered_by === "robot" &&
                              op?.original?.is_voice_note ? (
                                "-"
                              ) : (
                                <WavePlayer
                                  audio={
                                    op?.original.recording_url?.startsWith(
                                      "http://"
                                    ) ||
                                    op?.original.recording_url?.startsWith(
                                      "https://"
                                    )
                                      ? op?.original.recording_url
                                      : audioUrl + op?.original.recording_url
                                  }
                                  index={op?.index}
                                  currentPlayingIndex={currentPlayingIndex}
                                  setCurrentPlayingIndex={
                                    setCurrentPlayingIndex
                                  }
                                />
                              )}
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </td>
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={"Sentiments"}
                      >
                        <p className="flex cursor-pointer items-center justify-end gap-2 rounded-l-full sm:justify-center">
                          {/* {receivedData?.rowData?.is_started ? op?.original.call_sentiment : ""} */}
                          {op?.original?.call_sentiment &&
                          op?.original?.answered_by !== "robot" &&
                          op?.original?.newStatus !== "Failed" ? (
                            <span>
                              {op?.original?.call_sentiment?.includes(
                                "positive"
                              ) ? (
                                <Tag variant="solid" colorScheme="green">
                                  {t("playground.positive")}
                                </Tag>
                              ) : op?.original?.call_sentiment?.includes(
                                  "negative"
                                ) ? (
                                <Tag variant="solid" colorScheme="red">
                                  {t("playground.negative")}
                                </Tag>
                              ) : (
                                <Tag variant="solid" colorScheme="blue">
                                  {t("playground.neutral")}
                                </Tag>
                              )}
                            </span>
                          ) : (
                            <span>-</span>
                          )}
                        </p>
                      </td>
                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={"Status"}
                      >
                        <p className="flex min-h-5 items-center justify-end gap-2 sm:justify-center">
                          {op?.original?.answered_by !== "robot" &&
                            op?.original?.call_sid &&
                            op?.original?.call_status &&
                            Number(op?.original?.call_duration_in_sec) > 0 && (
                              <Tag variant="solid" colorScheme="green">
                                {t("playground.completed")}
                              </Tag>
                            )}
                          {op?.original?.answered_by !== "robot" &&
                            op?.original?.call_sid &&
                            !op?.original?.call_status &&
                            Number(op?.original?.call_duration_in_sec) ===
                              0 && (
                              <span>
                                {timeStatus(
                                  op?.original?.created_at,
                                  op?.original?.maximum_time_per_call
                                ) ? (
                                  <Tag variant="solid" colorScheme="blue">
                                    {t("playground.inProgress")}
                                  </Tag>
                                ) : (
                                  <Tag variant="solid" colorScheme="yellow">
                                    {t("playground.failed")}
                                  </Tag>
                                )}
                              </span>
                            )}

                          {/* {op?.original?.call_sid &&
                            !op?.original?.call_status &&
                            Number(op?.original?.call_duration_in_sec) === 0 && (
                              <Tag variant="solid" colorScheme="blue">
                                In Progress
                              </Tag>
                            )} */}
                          {op?.original?.answered_by === "robot" &&
                            !op?.original?.is_voice_note && (
                              <Tag variant="solid" colorScheme="yellow">
                                {t("playground.failed")}
                              </Tag>
                            )}
                          {op?.original?.answered_by === "robot" &&
                            op?.original?.is_voice_note && (
                              <Tag variant="solid">
                                {t("playground.voiceNoteSent")}
                              </Tag>
                            )}
                        </p>
                      </td>

                      <td
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={"Actions"}
                      >
                        <div className="gap-1 text-center">
                          {op?.original?.answered_by !== "robot" &&
                          op?.original?.newStatus !== "Failed" ? (
                            <div>
                              {op?.original?.is_call_transferred && (
                                <div className="p-1">
                                  <Tag variant="solid">
                                    {t("playground.callTransferred")}
                                  </Tag>
                                </div>
                              )}
                              {op?.original?.is_message_sent && (
                                <div className="p-1">
                                  <Tag variant="solid">
                                    {t("playground.messageSent")}
                                  </Tag>
                                </div>
                              )}
                              {op?.original?.is_meeting_scheduled && (
                                <div className="p-1">
                                  <Tag variant="solid">
                                    {t("campaign.meetingScheduled")}
                                  </Tag>
                                </div>
                              )}
                              {op?.original?.is_email_sent && (
                                <div className="p-1">
                                  <Tag variant="solid">
                                    {t("campaign.emailSent")}
                                  </Tag>
                                </div>
                              )}
                              {op?.original?.is_whatsapp_sent && (
                                <div className="p-1">
                                  <Tag variant="solid">
                                    {t("playground.wMessageSent")}
                                  </Tag>
                                </div>
                              )}

                              {!op?.original?.is_call_transferred &&
                                !op?.original?.is_message_sent &&
                                !op?.original?.is_meeting_scheduled &&
                                !op?.original?.is_whatsapp_sent &&
                                !op?.original?.is_email_sent && <span>-</span>}
                            </div>
                          ) : (
                            <div>-</div>
                          )}
                        </div>
                      </td>
                      <td
                        className="min-w-[100px] border-white/0 py-3 pr-4"
                        data-label={"FeedBack"}
                      >
                        <p className="flex items-center justify-end gap-2 sm:justify-center">
                          <button
                            onClick={() => {
                              handleFeedbackOpen(op?.original?.id);
                              if (Array.isArray((window as any).dataLayer)) {
                                (window as any).dataLayer.push({
                                  event: "Feedback",
                                  buttonName: "Feedback ",
                                });
                              }
                            }}
                            className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                          >
                            <FaFlag />
                          </button>
                        </p>
                      </td>
                      <td
                        className="min-w-[50px] border-white/0 py-3 pr-4"
                        data-label={"Favourite"}
                      >
                        <p className="flex items-center justify-end gap-2 whitespace-nowrap sm:justify-center">
                          <TooltipHorizon
                            trigger={
                              <span
                                className="flex h-fit cursor-pointer items-center justify-center  gap-2 px-[6px] py-[6px] text-base font-medium text-yellow-700 transition duration-200 "
                                onClick={() => {
                                  doFavourite(op?.original);
                                  if (
                                    Array.isArray((window as any).dataLayer)
                                  ) {
                                    (window as any).dataLayer.push({
                                      event: "Favourite",
                                      buttonName: "Favourite",
                                    });
                                  }
                                }}
                              >
                                {op.original?.is_fav ? (
                                  <FaStar />
                                ) : (
                                  <FaRegStar />
                                )}
                              </span>
                            }
                            placement="bottom"
                            content={
                              op.original?.is_fav
                                ? "Favourite"
                                : "Non Favourite"
                            }
                            extra="rounded text-[11px]"
                          />
                        </p>
                      </td>
                    </tr>
                  ))}
                {/* {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell: any) => {
                      const columnHeader = cell.column.id;
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3 pr-4"
                          data-label={columnHeader}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))} */}
              </tbody>
            </table>
          </div>
          <div className="mb-2 mt-2 flex flex-wrap items-center justify-start gap-2">
            <button
              className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
              onClick={() => table.setPageIndex(0)}
              disabled={pagination?.pageIndex === 0}
            >
              {"<<"}
            </button>
            <button
              className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {"<"}
            </button>
            <div className="flex items-center gap-2">
              <span>
                {t("page")} {table.getState().pagination.pageIndex + 1}{" "}
                {t("of")} {totalPages}
              </span>
              <input
                type="number"
                min="1"
                max={totalPages}
                value={table.getState().pagination.pageIndex + 1}
                onChange={(e) => table.setPageIndex(Number(e.target.value) - 1)}
                className="w-[60px] rounded border border-gray-300 bg-gray-50 pl-2 text-base font-medium text-gray-700 dark:bg-navy-900 dark:text-white"
              />
              <button
                className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {">"}
              </button>
              <button
                className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                onClick={() => table.setPageIndex(totalPages - 1)}
                disabled={!table.getCanNextPage()}
              >
                {">>"}
              </button>
            </div>
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <PlayGroundDetailModal
            isOpen={isOpenModal}
            handleClose={handleCloseModal}
            playgroundData={playGroundData}
          />
          <ConfirmRedailCallModal
            isOpen={isOpenRedailModal}
            handleClose={handleCloseRedailModal}
            setIsRedialConfirm={setIsRedialConfirm}
            playgroundData={playGroundData}
          />
          <PlaygroundFeedBackModal
            onClose={handleFeedbackClose}
            isOpen={feedbackModalOpen}
            call_type={"playground"}
            call_id={callId}
          />
        </Card>
      )}
    </div>
  );
}

export default PlayGroundHistory;
const columnHelper = createColumnHelper<RowObj>();
