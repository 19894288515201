/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { getContactList } from "services/contact";
import { useToast } from "@chakra-ui/react";
import { deleteContactList } from "services/contact";
import Loader from "components/Loader/Loader";

import Card from "components/card";
import { MdEdit, MdPermContactCalendar } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import ConfirmModal from "components/modal/ConfirmModal";
import ContactEditModal from "components/modal/ContactEditModal";
import ContactAddModal from "components/modal/ContactAddModal";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import SearchBarInput from "views/admin/assitstants/assistanceList/components/SearchbarInput";
import { GoPlus } from "react-icons/go";
import TooltipHorizon from "components/tooltip";
import { useTranslation } from "react-i18next";
type RowObj = {
  name: string;
  description: any;
  action: any;
  contact_counts: number;
};
const Segments = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
  const [isRequest, setIsRequest] = useState(true);
  const [contactData, setContactData] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [id, setId] = useState();
  const [isDeleted, setIsDeleted] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [rowData, setRowData] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const fetchData = useCallback(() => {
    const params = {
      page: pagination?.pageIndex,
      perpage: pagination?.pageSize,
      search: searchQuery,
    };
    fetchSements(params);
  }, [pagination?.pageIndex, pagination?.pageSize, searchQuery]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  useEffect(() => {
    const params = {
      page: pagination?.pageIndex,
      perpage: pagination?.pageSize,
      search: searchQuery,
    };
    fetchSements(params);
  }, [refresh]);
  const fetchSements = (params: any) => {
    setIsRequest(true);
    getContactList(params)
      .then((response) => {
        if (response.success) {
          setIsRequest(false);
          if (response?.data) {
            setContactData(response?.data);
          } else {
            setContactData([]);
          }
          setTotalPages(response?.totalPages || 0);
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          setIsRequest(false);
        }
      })
      .catch((error) => {
        setIsRequest(false);
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    if (isConfirm === true) {
      deleteContactList(id)
        .then((response) => {
          if (response.success) {
            setIsDeleted(true);
            setIsRequest(false);
            setRefresh(!refresh);
            toast({
              description: t("messages.segmentDeleted"),
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.error("Error submitting feedback:", error);
        });
    }
  }, [isConfirm]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = (value: any) => {
    // const value = e.target.value.toLowerCase();
    setSearchQuery(value);
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    // if (contactDataAll) {
    //   const filteredData = contactDataAll.filter((item: any) => {
    //     return Object.keys(item).some((key) => {
    //       const itemValue = item[key];

    //       if (typeof itemValue === "string") {
    //         return itemValue.toLowerCase().includes(value);
    //       }

    //       if (typeof itemValue === "number") {
    //         return itemValue.toString().includes(value);
    //       }

    //       if (itemValue instanceof Date) {
    //         return itemValue.toLocaleDateString().includes(value);
    //       }

    //       return false;
    //     });
    //   });
    //   setContactData(filteredData);
    // }
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleModal = (rowData: any) => {
    setRowData(rowData?.row?.original);
    setIsModalOpen(!isModalOpen);
    setIsConfirm(false);
  };

  const handleConfirmClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirmModal = (rowData: any) => {
    setRowData(rowData?.row?.original);
    setIsConfirmModalOpen(!isModalOpen);
    setId(rowData?.row?.original?.id);
    setIsConfirm(false);
  };

  const handleAddContactClose = () => {
    setIsAddContactModalOpen(false);
  };

  const handleAddContactModal = () => {
    setIsAddContactModalOpen(!isAddContactModalOpen);
  };

  useEffect(() => {
    if (isDeleted === true) {
      handleConfirmClose();
    }
  }, [isDeleted]);

  const handleClick = (items: any) => {
    localStorage.setItem("contactData", JSON.stringify(items.row.original));
    navigate(`/admin/segment/audiance`, {
      state: { rowData: items.row.original },
    });
  };
  const CustomHeader = ({ headerText }: any) => (
    <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
      {headerText}
    </p>
  );
  const columns = [
    columnHelper.accessor("name", {
      id: "Name",
      header: () => <CustomHeader headerText={t("segments.name")} />,
      cell: (info) => (
        <p
          onClick={() => handleClick(info)}
          className="flex cursor-pointer items-center justify-end text-sm font-bold text-navy-700 hover:text-blue-700 hover:underline hover:underline-offset-[4px] dark:text-white sm:justify-center"
        >
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("description", {
      id: "Description",
      header: () => <CustomHeader headerText={t("segments.description")} />,
      cell: (info: any) => (
        <TooltipHorizon
          trigger={
            <div className="m-auto max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap sm:justify-center md:text-center">
              {info.getValue()}
            </div>
          }
          placement="top"
          content={info.getValue()}
          extra="rounded text-[11px]"
        />
      ),
    }),
    columnHelper.accessor("contact_counts", {
      id: "Total Contacts",
      header: () => <CustomHeader headerText={t("segments.totalContacts")} />,
      cell: (info) => (
        <p className="flex items-center justify-end text-sm font-bold text-navy-700 dark:text-white sm:justify-center">
          {info.getValue() || 0}
        </p>
      ),
    }),
    columnHelper.accessor("action", {
      id: "Actions",
      header: () => <CustomHeader headerText={t("segments.actions")} />,
      cell: (info) => (
        <div className="flex  items-center justify-end gap-4 sm:justify-center">
          <MdEdit
            onClick={() => {
              handleModal(info);
              if (Array.isArray((window as any).dataLayer)) {
                (window as any).dataLayer.push({
                  event: "Edit Contact",
                  buttonName: "Edit Contact",
                });
              }
            }}
            color="gray"
            className="h-6 w-6 cursor-pointer"
          />
          <MdDelete
            onClick={() => {
              handleConfirmModal(info);
              if (Array.isArray((window as any).dataLayer)) {
                (window as any).dataLayer.push({
                  event: "Delete Contact",
                  buttonName: "Delete Contact",
                });
              }
            }}
            color="gray"
            className="h-6 w-6 cursor-pointer"
          />
        </div>
      ),
    }),
  ];
  const table = useReactTable({
    data: contactData,
    columns: columns,
    state: {
      pagination,
    },
    pageCount: totalPages,
    manualPagination: true,
    manualFiltering: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });
  return (
    <div className="fourth-step relative">
      {isRequest && <Loader />}
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        {/* <DevelopmentTable
          tableData={contactData}
          setContactData={setContactData}
          searchQuery={searchQuery}
          handleSearch={handleSearch}
          setIsConfirm={setIsConfirm}
          setId={setId}
          isDeleted={isDeleted}
          setRefresh={setRefresh}
          refresh={refresh}
          isRequest={isRequest}
        /> */}
        <div>
          <div className="mb-2">
            <div className="mt-2 flex justify-end gap-4 py-2">
              <SearchBarInput
                initialData={searchQuery}
                handleFilteredData={handleSearch}
              />
              <button
                onClick={() => handleAddContactModal()}
                className="flex items-center justify-center gap-1  rounded bg-blue-700 px-6 py-0 text-white hover:bg-blue-800"
              >
                <GoPlus className="h-7 w-7" />
                {t("addNew")}
              </button>
            </div>
          </div>

          {contactData?.length === 0 && !isRequest ? (
            <Card extra={"w-full sm:overflow-auto px-6"}>
              <div className="mb-auto flex flex-col items-center justify-center">
                <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
                  <MdPermContactCalendar />
                </div>
                <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
                  {t("segments.noSegment")}
                </h4>
                <p className="px-5 text-center text-base font-normal text-gray-600 md:!px-0 xl:!px-8">
                  {t("segments.getStarted")}
                </p>
                <button
                  onClick={() => {
                    handleAddContactModal();

                    if (Array.isArray((window as any).dataLayer)) {
                      (window as any).dataLayer.push({
                        event: "Add New Contact",
                        buttonName: "Add new",
                      });
                    }
                  }}
                  className="mb-10 mt-10 flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
                >
                  <GoPlus className="h-7 w-7" />
                  {t("addNew")}
                </button>
              </div>
            </Card>
          ) : (
            <Card extra={"w-full sm:overflow-auto px-6"}>
              <div className="mt-1 overflow-x-auto">
                <table className="responsive-table w-full">
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr
                        key={headerGroup.id}
                        className="!border-px !border-gray-400"
                      >
                        {headerGroup.headers.map((header) => {
                          return (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              onClick={header.column.getToggleSortingHandler()}
                              scope="col"
                              className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                            >
                              <div className="items-center justify-between text-xs text-gray-200">
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: "",
                                  desc: "",
                                }[header.column.getIsSorted() as string] ??
                                  null}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell: any) => {
                          const columnHeader = cell.column.id;
                          return (
                            <td
                              key={cell.id}
                              className="min-w-[150px] border-white/0 py-3 pr-4"
                              data-label={columnHeader}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="mb-2 mt-2 flex flex-wrap items-center justify-start gap-2">
                <button
                  className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                  onClick={() => table.setPageIndex(0)}
                  disabled={pagination?.pageIndex === 0}
                >
                  {"<<"}
                </button>
                <button
                  className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<"}
                </button>
                <div className="flex items-center gap-2">
                  <span>
                    {t("page")} {table.getState().pagination.pageIndex + 1}{" "}
                    {t("of")} {totalPages}
                  </span>
                  <input
                    type="number"
                    min="1"
                    max={totalPages}
                    value={table.getState().pagination.pageIndex + 1}
                    onChange={(e) =>
                      table.setPageIndex(Number(e.target.value) - 1)
                    }
                    className="w-[60px] rounded border border-gray-300 bg-gray-50 pl-2 text-base font-medium text-gray-700 dark:bg-navy-900 dark:text-white"
                  />
                  <button
                    className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    {">"}
                  </button>
                  <button
                    className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                    onClick={() => table.setPageIndex(totalPages - 1)}
                    disabled={!table.getCanNextPage()}
                  >
                    {">>"}
                  </button>
                </div>
                <select
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </Card>
          )}
          <ContactEditModal
            isOpen={isModalOpen}
            handleClose={handleClose}
            rowData={rowData}
            setContactData={setContactData}
            setRefresh={setRefresh}
            refresh={refresh}
          />
          <ContactAddModal
            isOpen={isAddContactModalOpen}
            handleClose={handleAddContactClose}
            setContactData={setContactData}
            setRefresh={setRefresh}
            refresh={refresh}
          />
          <ConfirmModal
            isOpen={isConfirmModalOpen}
            handleClose={handleConfirmClose}
            setIsConfirm={setIsConfirm}
            setRefresh={setRefresh}
            refresh={refresh}
            type={t("segments.segment")}
          />
        </div>
      </div>
    </div>
  );
};

export default Segments;
const columnHelper = createColumnHelper<RowObj>();
