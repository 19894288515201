import React, { useEffect, useState } from "react";
import Card from "components/card";
/* eslint-disable react-hooks/exhaustive-deps */
// import Nft3 from "../../../assets/img/nfts/Nft3.png";
import avatar1 from "../../../../assets/img/avatars/avatar1.png";
import avatar2 from "../../../../assets/img/avatars/avatar2.png";
import avatar3 from "../../../../assets/img/avatars/avatar3.png";
import { MdDelete, MdPeople } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import ConfirmModal from "components/modal/ConfirmModal";
import { useNavigate } from "react-router-dom";
import SearchBar from "./components/Searchbar";
import AssistantModal from "./components/AssitantModal";
import { FaPhoneAlt } from "react-icons/fa";
import { useToast } from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import { deleteAssistant, getAssitantList } from "services/assitant";
import { GoPlus } from "react-icons/go";
import TooltipHorizon from "components/tooltip";
import { useTranslation } from "react-i18next";

const AssistanceList = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isAssitantModalOpen, setIsAssistantModalOpen] = useState(false);
  const [assistant, setAssistant] = useState<any[]>([]);
  const [assistantAll, setAssistantAll] = useState<any[]>([]);
  const [assistantSelectedData, setAssistantSelectedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showInput, setShowInput] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [rowId, setRowId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleModal = (data: any) => {
    setIsModalOpen(!isModalOpen);
    setRowId(data?.id);
  };

  const handleAssistantClose = () => {
    setIsAssistantModalOpen(false);
    setShowInput(false);
  };

  const handleAssistantModal = (data: any) => {
    setAssistantSelectedData(data);
    setIsAssistantModalOpen(!isAssitantModalOpen);

    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: "Test Call",
        buttonName: "Test Call",
      });
    }
  };

  useEffect(() => {
    getAssitantList()
      .then((response: any) => {
        setLoading(false);
        if (response.success) {
          if (response?.data) {
            setAssistant(response.data);
            setAssistantAll(response.data);
          } else {
            setAssistant([]);
            setAssistantAll([]);
          }
        }
      })
      .catch((error: string) => {
        setLoading(false);
        console.error("Error :", error);
      });
  }, [refresh]);

  const handleSetup = (data: any) => {
    navigate("/admin/assistants/update-assistant", {
      state: { formData: data },
    });

    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: "Settings",
        buttonName: "Settings",
      });
    }
  };

  useEffect(() => {
    if (isConfirm === true) {
      deleteAssistant(rowId)
        .then((response) => {
          if (response.success) {
            handleClose();
            setRefresh(!refresh);
            toast({
              description: t("messages.assistantDeleted"),
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.error("Error Deleting Assistant:", error);
        });
    }
  }, [isConfirm]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = (e: any) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (assistant) {
      const filteredData = assistantAll.filter(
        (item: any) =>
          typeof item?.name === "string" &&
          item?.name?.toLowerCase()?.includes(value.toLowerCase())
      );
      setAssistant(filteredData);
    }
  };
  const goCreateAssistant = () => {
    navigate("/admin/assistants/create-assistant");

    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: "New Assistant",
        buttonName: " NEW ASSISTANT",
      });
    }
  };
  return (
    <div className="first-step relative">
      {loading && <Loader />}
      <div className="my-5">
        <div className="flex justify-end gap-4">
          <SearchBar
            initialData={searchQuery}
            handleFilteredData={handleSearch}
          />
          <button
            onClick={() => goCreateAssistant()}
            className="rounded bg-blue-700 px-6 py-0 text-white hover:bg-blue-800"
          >
            {t("assistants.addAssistant")}
          </button>
        </div>
      </div>
      {assistant.length === 0 && !loading ? (
        <Card extra={"w-full h-full p-4"}>
          <div className="mb-auto flex flex-col items-center justify-center">
            <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
              <MdPeople />
            </div>
            <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
              {t("assistants.noAssistant")}
            </h4>
            <p className="px-5 text-center text-base font-normal text-gray-600 md:!px-0 xl:!px-8">
              {t("assistants.getStarted")}
            </p>
            <button
              onClick={() => goCreateAssistant()}
              className="mb-10 mt-10 flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
            >
              <GoPlus className="h-7 w-7" />
              {t("assistants.addAssistant")}
            </button>
          </div>
        </Card>
      ) : (
        <div className="z-20 mt-2 grid grid-cols-1 gap-5 md:grid-cols-2 xxl:grid-cols-3">
          {assistant.map((data, index) => (
            <Card
              key={index}
              extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white`}
              bidders={[avatar1, avatar2, avatar3]}
              title={data.title}
              author={data.author}
              price={data.price}
            >
              <div className="h-full w-full">
                <div className="flex items-center justify-between gap-3">
                  <div className="flex w-[80%] items-center justify-start gap-3">
                    {data?.assistant_image && (
                      <img
                        src={data?.assistant_image}
                        alt="assistant img"
                        className={`h-12 w-12 cursor-pointer rounded-full border-2`}
                      />
                    )}
                    <h3 className="w-[160px] overflow-hidden text-ellipsis whitespace-nowrap text-xl font-bold md:w-[80%]">
                      {data?.name}
                    </h3>
                  </div>
                  <div className="flex w-[20%] justify-end gap-4">
                    <IoMdSettings
                      className="h-6 w-6 cursor-pointer"
                      onClick={() => handleSetup(data)}
                    />
                    <MdDelete
                      onClick={() => {
                        handleModal(data);
                        setIsConfirm(false);
                      }}
                      className="h-6 w-6 cursor-pointer"
                    />
                  </div>
                </div>
                <div className="mt-3 border border-gray-300"></div>
                <div className="mt-3">
                  <TooltipHorizon
                    trigger={
                      <h3 className="mt-2 max-w-fit overflow-hidden text-ellipsis whitespace-nowrap text-xl font-bold">
                        {data?.goal}
                      </h3>
                    }
                    placement="top"
                    content={data?.goal}
                    extra="rounded text-[11px]"
                  />
                  <p className="mt-2">{data?.company_name}</p>
                </div>
                <div className="mt-5 flex justify-around gap-3">
                  <button
                    onClick={() => handleSetup(data)}
                    className="border-black flex w-full items-center justify-center gap-1 rounded border bg-[#000000] px-6 py-2 text-lg text-white"
                  >
                    <IoMdSettings className="h-4 w-4 cursor-pointer" />
                    {t("assistants.setup")}
                  </button>
                  <button
                    onClick={() => handleAssistantModal(data)}
                    className="border-blueSecondary-900 flex w-full items-center justify-center gap-1 rounded border bg-blue-700 px-6 py-2 text-lg text-white hover:bg-blue-800"
                  >
                    <FaPhoneAlt className="h-4 w-4 cursor-pointer" />
                    {t("assistants.test")}
                  </button>
                </div>
              </div>
            </Card>
          ))}
        </div>
      )}
      <ConfirmModal
        isOpen={isModalOpen}
        handleClose={handleClose}
        rowData={rowId}
        setIsConfirm={setIsConfirm}
        type={t("assistants.assistant")}
      />
      <AssistantModal
        isOpen={isAssitantModalOpen}
        handleClose={handleAssistantClose}
        setShowInput={setShowInput}
        showInput={showInput}
        assistantSelectedData={assistantSelectedData}
      />
    </div>
  );
};

export default AssistanceList;
