/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useToast,
} from "@chakra-ui/react";
import SaveConfirmModal from "./../SaveConfirmModal";
import { getAllCompanyList, updateDeal } from "services/crm";
import Loader from "components/Loader/Loader";
import { useTranslation } from "react-i18next";

const DealEditModal = ({
  isOpen,
  handleClose,
  rowData,
  setRefresh,
  refresh,
}: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [allCompanyList, setAllCompanyList] = useState([]);
  useEffect(() => {
    if (isOpen) {
      fetchCompanyList();
    }
  }, [isOpen]);
  const fetchCompanyList = () => {
    setIsLoading(true);
    let param = {
      page: -1,
    };
    getAllCompanyList(param)
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
          if (response?.data) {
            setAllCompanyList(response?.data);
          } else {
            setAllCompanyList([]);
          }
        } else {
          toast({
            description: response?.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  const validationSchema = Yup.object().shape({
    dealName: Yup.string().required(t("contactDeals.dealNameValidation")),
    amount: Yup.string().required(t("contactDeals.amountValidation")),
    stage: Yup.string().required(t("contactDeals.stageValidation")),
    companyId: Yup.string().required(t("contactDeals.companyValidation")),
  });

  const formik = useFormik({
    initialValues: {
      dealName: rowData?.deal_name || "",
      amount: rowData?.amount || "",
      stage: rowData?.stage || "",
      companyId: rowData?.company_id || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      const payload = {
        deal_name: values.dealName,
        amount: values.amount,
        stage: values.stage,
        company_id: values?.companyId,
      };
      updateDeal(payload, rowData?.id).then((response: any) => {
        if (response.success) {
          setRefresh(!refresh);
          toast({
            description: t("messages.dealUpdated"),
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          handleClose();
        } else {
          toast({
            description: response.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      });
    },
  });

  useEffect(() => {
    if (rowData && isOpen) {
      formik.setValues({
        dealName: rowData?.deal_name || "",
        amount: rowData?.amount || "",
        stage: rowData?.stage || "",
        companyId: rowData?.company_id || "",
      });
    }
  }, [rowData, isOpen]);

  const checkIsDirtyForm = () => {
    let initialValue = {
      dealName: rowData?.deal_name || "",
      amount: rowData?.amount || "",
      stage: rowData?.stage || "",
      companyId: rowData?.company_id || "",
    };
    let isDirty =
      JSON.stringify(initialValue) !== JSON.stringify(formik?.values);
    if (isDirty) {
      openWarningModal();
    } else {
      formik.resetForm();
      handleClose();
    }
  };
  const openWarningModal = () => {
    setIsOpenWarning(true);
  };
  const closeWarningModal = () => {
    setIsOpenWarning(false);
  };
  const onConfirm = () => {
    handleClose();
    formik.resetForm();
    setIsOpenWarning(false);
  };
  const onCancel = () => {
    setIsOpenWarning(false);
  };
  return (
    <>
      <Drawer
        placement="right"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={checkIsDirtyForm}
        // scrollBehavior={"inside"}
        blockScrollOnMount={false}
        size={"sm"}
      >
        {isLoading && <Loader />}
        <DrawerOverlay />
        <DrawerContent className="dark:bg-navy-900">
          <DrawerHeader>{t("contactDeals.editDeal")}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody pb={6}>
            <FormControl mt={4}>
              <FormLabel>{t("contactDeals.dealName")}*</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="dealName"
                type="text"
                value={formik.values.dealName}
                onChange={formik.handleChange}
              />
              {formik.touched.dealName && formik.errors.dealName ? (
                <div className="text-red-500">
                  {formik.errors.dealName.toString()}
                </div>
              ) : null}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{t("contactDeals.amount")}*</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="amount"
                type="number"
                value={formik.values.amount}
                onChange={formik.handleChange}
              />
              {formik.touched.amount && formik.errors.amount ? (
                <div className="text-red-500">
                  {formik.errors.amount.toString()}
                </div>
              ) : null}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{t("contactDeals.stage")}*</FormLabel>
              {/* <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="stage"
                type="text"
                value={formik.values.stage}
                onChange={formik.handleChange}
              /> */}
              <select
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="stage"
                name="stage"
                value={formik.values.stage}
                onChange={formik.handleChange}
              >
                <option value="">{t("contactDeals.selectStage")}</option>
                <option value="Appointment Scheduled">
                  {t("contactDeals.stageOption1")}
                </option>
                <option value="Qualified To Buy">
                  {t("contactDeals.stageOption2")}
                </option>
                <option value="Presentation Scheduled">
                  {t("contactDeals.stageOption3")}
                </option>
                <option value="Decision Maker Bought-In">
                  {t("contactDeals.stageOption4")}
                </option>
                <option value="Contract Sent">
                  {t("contactDeals.stageOption5")}
                </option>
                <option value="Closed Won">
                  {t("contactDeals.stageOption6")}
                </option>
                <option value="Closed Lost">
                  {t("contactDeals.stageOption7")}
                </option>
              </select>
              {formik.touched.stage && formik.errors.stage ? (
                <div className="text-red-500">
                  {formik.errors.stage.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("contactDeals.company")}*</FormLabel>
              <select
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="companyId"
                name="companyId"
                value={formik.values.companyId}
                onChange={formik.handleChange}
              >
                <option value="">Select a company</option>
                {allCompanyList &&
                  allCompanyList.map((op, i) => (
                    <option key={i} value={op?.company_id}>
                      {op?.company_name}
                    </option>
                  ))}
              </select>
              {formik.touched.companyId && formik.errors.companyId ? (
                <div className="text-red-500">
                  {formik.errors.companyId.toString()}
                </div>
              ) : null}
            </FormControl>
          </DrawerBody>

          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("save")}
            </Button>
            <Button onClick={checkIsDirtyForm}>{t("cancel")}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <SaveConfirmModal
        isOpen={isOpenWarning}
        onClose={closeWarningModal}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </>
  );
};

export default DealEditModal;
