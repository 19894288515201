/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useColorMode,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import { getContactList, updateAudience } from "services/contact";
import { useToast } from "@chakra-ui/react";
import { convertToE164, isValidPhoneNumber } from "utils/utils";
import SaveConfirmModal from "./SaveConfirmModal";
import Loader from "components/Loader/Loader";
import { useTranslation } from "react-i18next";

const EditAudianceModal = ({
  isOpen,
  handleClose,
  rowData,
  setTableData,
  setRefresh,
  refresh,
  reference,
  contactData,
}: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { colorMode } = useColorMode();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [contactList, setContactList] = useState([]);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("audiences.fnameValidation")),
    lastName: Yup.string().required(t("audiences.lnameValidation")),
    contactNumber: Yup.string()
      .required(t("audiences.phoneValidation"))
      .test("valid-phone", t("audiences.phoneInvalidValidation"), (value) => {
        return isValidPhoneNumber(value);
      }),
    email: Yup.string()
      .required(t("requireEmail"))
      .email(t("audiences.emailValidation"))
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("audiences.emailValidation")),

    description: Yup.string().max(250, t("audiences.charValidation")),
    isAudiance: Yup.boolean(),
    segmentId: Yup.string().when(["isAudiance"], ([isAudiance], schema) => {
      if (isAudiance) {
        return Yup.string().required(t("audiences.segmentValidation"));
      }
      return schema;
    }),
  });

  const formik = useFormik({
    initialValues: {
      name: rowData?.first_name || "",
      lastName: rowData?.last_name || "",
      contactNumber: rowData?.phone || "",
      email: rowData?.email || "",
      description: rowData?.description || "",
      jobTitle: rowData?.job_title || "",
      lifecycleStage: rowData?.lifecycle_stage || "",
      leadStatus: rowData?.lead_status || "",
      isAudiance: reference === "contact" ? true : false,
      segmentId: reference === "contact" ? rowData?.segment_id : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      const payload = {
        contact_id: reference === "contact" ? rowData?.contact_id : rowData?.id,
        first_name: values?.name,
        last_name: values?.lastName,
        email: values?.email,
        phone: values?.contactNumber,
        description: values?.description,
        job_title: values?.jobTitle,
        lifecycle_stage: values?.lifecycleStage,
        lead_status: values?.leadStatus,
      };
      updateAudience(
        payload,
        reference === "contact" ? values?.segmentId : contactData?.id
      ).then((response: any) => {
        if (response.success) {
          setRefresh(!refresh);
          handleClose();
          toast({
            description:
              reference === "contact"
                ? t("messages.contactUpdated")
                : t("messages.audienceUpdated"),
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        } else {
          toast({
            description: response.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      });
    },
  });
  useEffect(() => {
    if (isOpen && reference === "contact") {
      fetchSegments();
    }
  }, [isOpen, reference]);
  const fetchSegments = () => {
    setIsRequest(true);
    let param = {
      page: -1,
      perpage: 10,
      search: "",
    };
    getContactList(param)
      .then((response) => {
        setIsRequest(false);
        if (response.success) {
          if (response?.data) {
            setContactList(response?.data);
          } else {
            setContactList([]);
          }
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsRequest(false);
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    if (rowData && isOpen) {
      formik.setValues({
        name: rowData?.first_name || "",
        lastName: rowData?.last_name || "",
        contactNumber: rowData?.phone || "",
        email: rowData?.email || "",
        description: rowData?.description || "",
        jobTitle: rowData?.job_title || "",
        lifecycleStage: rowData?.lifecycle_stage || "",
        leadStatus: rowData?.lead_status || "",
        isAudiance: reference === "contact" ? true : false,
        segmentId: reference === "contact" ? rowData?.segment_id || "" : "",
      });
    }
  }, [rowData, isOpen]);
  const checkIsDirtyForm = () => {
    let initialValue = {
      name: rowData?.first_name || "",
      lastName: rowData?.last_name || "",
      contactNumber: rowData?.phone || "",
      email: rowData?.email || "",
      description: rowData?.description || "",
      jobTitle: rowData?.job_title || "",
      lifecycleStage: rowData?.lifecycle_stage || "",
      leadStatus: rowData?.lead_status || "",
      isAudiance: reference === "contact" ? true : false,
      segmentId: reference === "contact" ? rowData?.segment_id || "" : "",
    };
    let isDirty =
      JSON.stringify(initialValue) !== JSON.stringify(formik?.values);
    if (isDirty) {
      openWarningModal();
    } else {
      formik.resetForm();
      handleClose();
    }
  };
  const openWarningModal = () => {
    setIsOpenWarning(true);
  };
  const closeWarningModal = () => {
    setIsOpenWarning(false);
  };
  const onConfirm = () => {
    handleClose();
    formik.resetForm();
    setIsOpenWarning(false);
  };
  const onCancel = () => {
    setIsOpenWarning(false);
  };
  return (
    <>
      <Drawer
        placement="right"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={checkIsDirtyForm}
        size={"xl"}
        blockScrollOnMount={false}
      >
        {isRequest && <Loader />}
        <DrawerOverlay />
        <DrawerContent maxW={"700px"} className="dark:bg-navy-900">
          <DrawerHeader>
            {t("update")} {reference === "contact" ? "Contact" : "Audience"}{" "}
          </DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody pb={6}>
            <div className="flex justify-between gap-3">
              <FormControl>
                <FormLabel>{t("audiences.fName")}*</FormLabel>
                <input
                  className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                   py-2 leading-tight focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                  id="name"
                  type="text"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="text-red-500">
                    {formik.errors.name.toString()}
                  </div>
                ) : null}
              </FormControl>
              <FormControl>
                <FormLabel>{t("audiences.lName")}*</FormLabel>
                <input
                  className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                   py-2 leading-tight focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                  id="lastName"
                  name="lastName"
                  type="text"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="text-red-500">
                    {formik.errors.lastName.toString()}
                  </div>
                ) : null}
              </FormControl>
            </div>
            <FormControl mt={4}>
              <FormLabel>{t("audiences.phone")}*</FormLabel>
              <PhoneInput
                inputStyle={{
                  background: colorMode === "dark" ? "#0B1437" : "#ffffff",
                  color: colorMode === "dark" ? "white" : "black",
                }}
                dropdownStyle={{
                  background: colorMode === "dark" ? "#0B1437" : "#ffffff",
                  color: colorMode === "dark" ? "white" : "black",
                }}
                inputProps={{
                  name: "contactNumber",
                  id: "contactNumber",
                  required: true,
                  className:
                    "pl-[50px] dark:bg-slate-900 inline-flex w-full border-[1px] items-center gap-x-2 rounded border border-gray-200 bg-white px-4 py-3 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-white focus:border-blue-500 focus:bg-white focus:outline-none",
                }}
                buttonStyle={{
                  borderRadius: "8px",
                }}
                country={"us"}
                value={formik.values.contactNumber}
                onChange={(value, country, event, formattedValue) => {
                  let myPhone = convertToE164(formattedValue);
                  formik.setFieldValue("contactNumber", myPhone);
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.contactNumber && formik.errors.contactNumber ? (
                <div className="text-red-500">
                  {formik.errors.contactNumber.toString()}
                </div>
              ) : null}
            </FormControl>
            {reference === "contact" && (
              <FormControl mt={4}>
                <FormLabel>{t("audiences.segment")}*</FormLabel>
                <select
                  className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                  id="segmentId"
                  name="segmentId"
                  value={formik.values.segmentId}
                  onChange={formik.handleChange}
                  disabled
                >
                  <option value="">{t("audiences.selectSegment")}</option>
                  {contactList &&
                    contactList.map((op, i) => (
                      <option key={i} value={op.id}>
                        {op.name}
                      </option>
                    ))}
                </select>
                {formik.touched.segmentId && formik.errors.segmentId ? (
                  <div className="text-red-500">
                    {formik.errors.segmentId.toString()}
                  </div>
                ) : null}
              </FormControl>
            )}
            <FormControl mt={4}>
              <FormLabel>{t("audiences.email")} *</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                 py-2 leading-tight focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500">
                  {formik.errors.email.toString()}
                </div>
              ) : null}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("audiences.jobTitle")}</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="jobTitle"
                name="jobTitle"
                type="text"
                value={formik.values.jobTitle}
                onChange={formik.handleChange}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("audiences.lifecycle")}</FormLabel>
              <select
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="lifecycleStage"
                name="lifecycleStage"
                value={formik.values.lifecycleStage}
                onChange={formik.handleChange}
              >
                <option value="">{t("audiences.selectStage")}</option>
                <option value="Subscriber">
                  {t("audiences.stageOption1")}
                </option>
                <option value="Lead">{t("audiences.stageOption2")}</option>
                <option value="Marketing Qualified Lead">
                  {t("audiences.stageOption3")}
                </option>
                <option value="Sales Qualified Lead">
                  {t("audiences.stageOption4")}
                </option>
                <option value="Opportunity">
                  {t("audiences.stageOption5")}
                </option>
                <option value="Customer">{t("audiences.stageOption6")}</option>
                <option value="Evangelist">
                  {t("audiences.stageOption7")}
                </option>
                <option value="Other">{t("audiences.stageOption8")}</option>
              </select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("audiences.leadStatus")}</FormLabel>
              <select
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="leadStatus"
                name="leadStatus"
                value={formik.values.leadStatus}
                onChange={formik.handleChange}
              >
                <option value="">{t("audiences.leadSelect")}</option>
                <option value="New">{t("audiences.leadOption1")}</option>
                <option value="Open">{t("audiences.leadOption2")}</option>
                <option value="In Progress">
                  {t("audiences.leadOption3")}
                </option>
                <option value="Open Deal">{t("audiences.leadOption4")}</option>
                <option value="Unqualified">
                  {t("audiences.leadOption5")}
                </option>
                <option value="Uttempted to contact">
                  {t("audiences.leadOption6")}
                </option>
                <option value="Connected">{t("audiences.leadOption7")}</option>
                <option value="Bad timing">{t("audiences.leadOption8")}</option>
              </select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("audiences.description")}</FormLabel>
              <textarea
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                id="description"
                name="description"
                rows={3}
                value={formik.values.description}
                onChange={(e) => {
                  if (e.target.value.length <= 250) {
                    formik.setFieldValue(
                      "description",
                      e.target.value.slice(0, 250)
                    );
                  }
                }}
              ></textarea>
              <div>
                <div className="text-right text-[14px] text-gray-700">
                  {formik.values.description.length}/250{" "}
                  {t("audiences.characters")}
                </div>
              </div>
            </FormControl>
          </DrawerBody>

          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("save")}
            </Button>
            <Button onClick={checkIsDirtyForm}>{t("cancel")}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <SaveConfirmModal
        isOpen={isOpenWarning}
        onClose={closeWarningModal}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </>
  );
};

export default EditAudianceModal;
