/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Card from "components/card";
import { Button, Progress, Tag, useToast } from "@chakra-ui/react";
import { FaChartLine, FaExternalLinkAlt } from "react-icons/fa";
import Loader from "components/Loader/Loader";
import { getDashboardData } from "services/dashboard";
import CallDurationTotal from "components/callDurationTotal";
import { MdOutlineHistory } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import callLimitIcon from "../../../assets/img/call-limit.png";
import weeklyIcon from "../../../assets/img/weekly.png";
import monthlyIcon from "../../../assets/img/monthly-call.png";
import totalCallIcon from "../../../assets/img/total-calls.png";
import WavePlayer from "components/waveSurferPlayer/WaveSurferPlayer";
import { useTranslation } from "react-i18next";
import CallLogDetailModal from "components/modal/CallLogDetailModal";
const Dashboard = () => {
  const { t } = useTranslation();
  const audioUrl = process.env.REACT_APP_URL_FOR_AUDIO;
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState<number | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  useEffect(() => {
    fetchSMTPData();
  }, []);

  const fetchSMTPData = () => {
    setLoading(true);
    getDashboardData()
      .then((response) => {
        setLoading(false);
        if (response.success) {
          setDashboardData(response?.data);
        } else {
          toast({
            description: response?.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goToRecording = () => {
    navigate("/admin/call-logs");
  };

  const goToCampaign = () => {
    navigate("/admin/campaigns");
  };
  const goToDetail = (rowData: any) => {
    navigate("/admin/campaigns-detail", {
      state: { rowData: rowData },
    });
  };
  const goToAssistant = () => {
    navigate("/admin/assistants");
  };
  const handleCloseModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleOpenModal = (rowData: any) => {
    setRowData(rowData);
    setIsModalOpen(!isModalOpen);
  };
  const timeStatus = (created_at: string, time_per_call: number) => {
    const createdAtDate = new Date(created_at);

    const calculatedTime = new Date(
      createdAtDate.getTime() + time_per_call * 60000
    );
    const currentTime = new Date();

    return currentTime.getTime() < calculatedTime.getTime();
  };
  let todayMinutConsumed =
    (Number(dashboardData?.todayCallMinutes || 0) /
      Number(dashboardData?.dailyLimit || 0)) *
    100;
  todayMinutConsumed = Number(todayMinutConsumed.toFixed(2));
  return (
    <div className="mt-4">
      {loading && <Loader />}
      <Card extra={"w-full sm:overflow-auto p-6 "}>
        <div className="first-step" style={{ position: "relative" }}>
          {dashboardData ? (
            <div>
              <div className="mb-6 grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-4">
                <div className="panel rounded border-[1px] border-gray-200 p-4 dark:border-[#ffffff33] dark:!bg-navy-900 dark:text-white dark:shadow-none">
                  <div className="flex w-full items-center">
                    <div className="w-full">
                      <div className="flex justify-between">
                        <div className="text-md font-semibold ltr:mr-1 rtl:ml-1">
                          {t("dashboard.todayCallLimit")}
                        </div>
                        <div>
                          <img
                            src={callLimitIcon}
                            alt="time"
                            className="h-12 w-12"
                          />
                        </div>
                      </div>
                      <div className="mt-2 flex items-center">
                        <div className="text-3xl font-bold ltr:mr-3 rtl:ml-3">
                          {" "}
                          {Number(
                            dashboardData?.todayCallMinutes || 0
                          )?.toFixed(2)}{" "}
                          / {Number(dashboardData?.dailyLimit || 0)}{" "}
                          <span className="text-sm">{t("Minutes")}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Progress
                    value={todayMinutConsumed}
                    size="md"
                    colorScheme="blue"
                    borderRadius="md"
                    mt={2}
                    hasStripe
                  />
                </div>
                <div className="panel rounded border-[1px] border-gray-200 p-4 dark:border-[#ffffff33] dark:!bg-navy-900 dark:text-white dark:shadow-none">
                  <div className="flex w-full items-center">
                    <div className="w-full">
                      <div className="flex justify-between">
                        <div className="text-md font-semibold ltr:mr-1 rtl:ml-1">
                          {t("dashboard.weeklyMinute")}
                        </div>
                        <div>
                          <img
                            src={weeklyIcon}
                            alt="time"
                            className="h-12 w-12"
                          />
                        </div>
                      </div>
                      <div className="mt-2 flex items-center">
                        <div className="text-3xl font-bold ltr:mr-3 rtl:ml-3">
                          {" "}
                          {Number(dashboardData?.weekCallMinutes || 0)?.toFixed(
                            2
                          )}{" "}
                          <span className="text-sm">{t("Minutes")}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel rounded border-[1px] border-gray-200 p-4 dark:border-[#ffffff33] dark:!bg-navy-900 dark:text-white dark:shadow-none">
                  <div className="flex w-full items-center">
                    <div className="w-full">
                      <div className="flex justify-between">
                        <div className="text-md font-semibold ltr:mr-1 rtl:ml-1">
                          {t("dashboard.monthlyMinute")}
                        </div>
                        <div>
                          <img
                            src={monthlyIcon}
                            alt="time"
                            className="h-12 w-12"
                          />
                        </div>
                      </div>
                      <div className="mt-2 flex items-center">
                        <div className="text-3xl font-bold ltr:mr-3 rtl:ml-3">
                          {" "}
                          {Number(
                            dashboardData?.monthCallMinutes || 0
                          )?.toFixed(2)}{" "}
                          <span className="text-sm">{t("Minutes")}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel rounded border-[1px] border-gray-200 p-4 dark:border-[#ffffff33] dark:!bg-navy-900 dark:text-white dark:shadow-none">
                  <div className="flex w-full items-center">
                    <div className="w-full">
                      <div className="flex justify-between">
                        <div className="text-md font-semibold ltr:mr-1 rtl:ml-1">
                          {t("dashboard.totalNumberOfCall")}
                        </div>
                        <div>
                          <img
                            src={totalCallIcon}
                            alt="call"
                            className="h-12 w-12"
                          />
                        </div>
                      </div>
                      <div className="mt-2 flex items-center">
                        <div className="text-3xl font-bold ltr:mr-3 rtl:ml-3">
                          {" "}
                          {dashboardData?.totalCalls || 0}
                        </div>
                        {/* <div className="badge bg-white/30">+ 2.35% </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-6 grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-2">
                <Card extra={"w-full sm:overflow-auto p-6"}>
                  <div className="flex items-center justify-between">
                    <p className="text-md font-bold">
                      {t("dashboard.latestCallRecording")}
                    </p>
                    <Button
                      colorScheme="blue"
                      variant="link"
                      className="flex items-center gap-2"
                      onClick={() => goToRecording()}
                    >
                      {t("dashboard.seeAll")}{" "}
                      <FaExternalLinkAlt className="h-4 w-4" />
                    </Button>
                  </div>
                  <div className="mt-8">
                    <table className="responsive-table w-full">
                      <thead className="border-b-2">
                        <tr>
                          <th scope="col">
                            <p className="flex items-center justify-center text-sm font-bold text-gray-600  dark:text-white">
                              {t("dashboard.phoneNumber")}
                            </p>
                          </th>
                          <th scope="col">
                            {" "}
                            <p className="flex cursor-pointer items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                              {t("dashboard.sentiment")}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                              {t("dashboard.callDuration")}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                              {t("dashboard.recording")}
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData?.letestCallRecordings?.length ? (
                          dashboardData?.letestCallRecordings.map(
                            (item: any, index: number) => (
                              <tr key={index}>
                                <td
                                  className="p-4"
                                  data-label={t("dashboard.phoneNumber")}
                                >
                                  {" "}
                                  <p
                                    onClick={() => handleOpenModal(item)}
                                    className="flex cursor-pointer items-center justify-center text-center text-sm text-blue-700 hover:underline dark:text-white"
                                  >
                                    {item?.phone}
                                  </p>
                                </td>
                                <td
                                  className="p-4"
                                  data-label={t("dashboard.sentiment")}
                                >
                                  {" "}
                                  <p className="flex items-center justify-center text-center text-sm text-navy-700 dark:text-white">
                                    {item?.call_sentiment ? (
                                      <span>
                                        {item?.call_sentiment?.includes(
                                          "positive"
                                        ) ? (
                                          <Tag
                                            variant="solid"
                                            colorScheme="green"
                                          >
                                            Positive
                                          </Tag>
                                        ) : item?.call_sentiment?.includes(
                                            "negative"
                                          ) ? (
                                          <Tag
                                            variant="solid"
                                            colorScheme="red"
                                          >
                                            Negative
                                          </Tag>
                                        ) : (
                                          <Tag
                                            variant="solid"
                                            colorScheme="blue"
                                          >
                                            Neutral
                                          </Tag>
                                        )}
                                      </span>
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </p>
                                </td>
                                <td
                                  className="p-4"
                                  data-label={t("dashboard.callDuration")}
                                >
                                  {" "}
                                  <p className="flex items-center justify-center text-center text-sm text-navy-700 dark:text-white">
                                    <CallDurationTotal
                                      totalDurationInSeconds={Number(
                                        item?.call_duration_in_sec || 0
                                      )}
                                    />
                                  </p>
                                </td>
                                <td
                                  className="min-w-[300px] p-4"
                                  data-label={t("dashboard.recording")}
                                >
                                  {" "}
                                  {/* <p className="flex items-center justify-center gap-4 rounded-full text-center font-semibold capitalize text-[#000]"> */}
                                  {item?.recording_url ? (
                                    <WavePlayer
                                      audio={
                                        item?.recording_url?.startsWith(
                                          "http://"
                                        ) ||
                                        item?.recording_url?.startsWith(
                                          "https://"
                                        )
                                          ? item?.recording_url
                                          : audioUrl + item?.recording_url
                                      }
                                      index={index}
                                      currentPlayingIndex={currentPlayingIndex}
                                      setCurrentPlayingIndex={
                                        setCurrentPlayingIndex
                                      }
                                    />
                                  ) : (
                                    "-"
                                  )}
                                  {/* </p> */}
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td className="p-4" colSpan={4}>
                              <div className="col-span-3 mb-auto flex flex-col items-center justify-center">
                                <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
                                  <MdOutlineHistory />
                                </div>
                                <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
                                  {t("dashboard.noRecording")}
                                </h4>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Card>
                <Card extra={"w-full sm:overflow-auto p-6"}>
                  <div className="flex items-center justify-between">
                    <p className="text-md font-bold">
                      {t("dashboard.campaignLists")}
                    </p>
                    <Button
                      colorScheme="blue"
                      variant="link"
                      className="flex items-center gap-2"
                      onClick={() => goToCampaign()}
                    >
                      {t("dashboard.seeAll")}{" "}
                      <FaExternalLinkAlt className="h-4 w-4" />
                    </Button>
                  </div>
                  <div className="mt-8">
                    <table className="responsive-table w-full">
                      <thead className="border-b-2">
                        <tr>
                          <th scope="col">
                            {" "}
                            <p className="flex cursor-pointer items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                              {t("dashboard.campaignsName")}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="flex items-center justify-center text-sm font-bold text-gray-600  dark:text-white">
                              {t("dashboard.assistantName")}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                              {t("dashboard.contactName")}
                            </p>
                          </th>
                          <th scope="col">
                            <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
                              {t("dashboard.dialerName")}
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData?.campaignList?.length ? (
                          dashboardData?.campaignList.map(
                            (item: any, index: number) => (
                              <tr key={index}>
                                <td
                                  className="p-4"
                                  data-label={t("dashboard.campaignsName")}
                                >
                                  {" "}
                                  <p
                                    onClick={() => goToDetail(item)}
                                    className="flex cursor-pointer items-center justify-center text-center text-sm text-blue-700 hover:underline dark:text-white"
                                  >
                                    {item?.campaigns_name}
                                  </p>
                                </td>
                                <td
                                  className="p-4"
                                  data-label={t("dashboard.assistantName")}
                                >
                                  {" "}
                                  <p className="flex items-center justify-center text-center text-sm text-navy-700 dark:text-white">
                                    {item?.assistant_name}
                                  </p>
                                </td>
                                <td
                                  className="p-4"
                                  data-label={t("dashboard.contactName")}
                                >
                                  {" "}
                                  <p className="flex items-center justify-center text-center text-sm text-navy-700 dark:text-white">
                                    {item?.contact_name}
                                  </p>
                                </td>
                                <td
                                  className="p-4"
                                  data-label={t("dashboard.dialerName")}
                                >
                                  {" "}
                                  <p className="flex items-center justify-center text-center text-sm text-navy-700 dark:text-white">
                                    {item?.dialer_name}
                                  </p>
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td className="p-4" colSpan={4}>
                              <div className="col-span-3 mb-auto flex flex-col items-center justify-center">
                                <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
                                  <MdOutlineHistory />
                                </div>
                                <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
                                  {t("dashboard.noCampagin")}
                                </h4>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Card>

                <CallLogDetailModal
                  isOpen={isModalOpen}
                  handleClose={handleCloseModal}
                  rowData={rowData}
                  timeStatus={timeStatus}
                />
              </div>
            </div>
          ) : (
            <>
              {!loading ? (
                <div
                  style={{ height: "80vh" }}
                  className="flex items-center justify-center"
                >
                  <div className="max-w-[400px]">
                    <FaChartLine className="mb-4 h-20 w-20 text-blue-700" />
                    <p className="mt-2 text-xl font-bold">
                      {t("dashboard.monitorOptimize")}
                    </p>
                    <p className="text-md mb-4">
                      {t("dashboard.performance")} <br />
                      {t("dashboard.track")}
                    </p>
                    <p className="text-md">
                      {t("dashboard.creatingAssistant")}
                    </p>
                    <button
                      onClick={goToAssistant}
                      className="mt-4 flex items-center justify-between gap-4 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
                    >
                      {t("dashboard.getStarted")}{" "}
                      <FaArrowRightLong className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  style={{ height: "80vh" }}
                  className="flex items-center justify-center"
                >
                  <div className="max-w-[400px]">
                    <p className="mt-2 text-xl font-bold">
                      {t("dashboard.loading")}
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Card>
    </div>
  );
};

export default Dashboard;
