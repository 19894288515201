/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Card from "components/card";
import { MdPermContactCalendar } from "react-icons/md";
import { GoPlus } from "react-icons/go";
import { getNotesList } from "services/crm";
import { useColorMode, useToast } from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import NoteAddModal from "components/modal/NoteAddModal";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { formatDateAmPm, responseHendler } from "utils/utils";
import { useTranslation } from "react-i18next";

const ContactNotes = ({ contactData }: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [noteList, setNoteList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [contactId] = useState(contactData?.contact_id);
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);
  const { colorMode } = useColorMode();

  useEffect(() => {
    fetchNoteList();
  }, [refresh]);
  const fetchNoteList = () => {
    setIsLoading(true);
    getNotesList(contactData?.contact_id)
      .then((response) => {
        setIsLoading(false);
        responseHendler(response);
        if (response.success) {
          if (response?.data) {
            setNoteList(response?.data);
          } else {
            setNoteList([]);
          }
        } else {
          toast({
            description: response?.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };

  const handleAddNoteClose = () => {
    setIsAddNoteModalOpen(false);
  };

  const handleAddNoteModal = () => {
    setIsAddNoteModalOpen(!isAddNoteModalOpen);
  };

  return (
    <div>
      {isLoading && <Loader />}
      <div className="mb-2">
        <div className="mt-2 flex justify-end gap-4 py-2">
          <button
            onClick={() => handleAddNoteModal()}
            className="flex h-[50px] items-center justify-center gap-1  rounded bg-blue-700 px-3 py-0 text-white hover:bg-blue-800"
          >
            <GoPlus className="h-7 w-7" />
            {t("addNew")}
          </button>
        </div>
      </div>

      {noteList?.length === 0 && !isLoading ? (
        <Card extra={"w-full sm:overflow-auto px-6"}>
          <div className="mb-auto flex flex-col items-center justify-center">
            <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
              <MdPermContactCalendar />
            </div>
            <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
              {t("contactNotes.noNote")}
            </h4>
            <p className="px-5 text-center text-base font-normal text-gray-600 md:!px-0 xl:!px-8">
              {t("contactNotes.getStarted")}
            </p>
            <button
              onClick={() => {
                handleAddNoteModal();

                if (Array.isArray((window as any).dataLayer)) {
                  (window as any).dataLayer.push({
                    event: "Add New Note",
                    buttonName: "Add new",
                  });
                }
              }}
              className="mb-10 mt-10 flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
            >
              <GoPlus className="h-7 w-7" />
              {t("addNew")}
            </button>
          </div>
        </Card>
      ) : (
        <div
          className={
            "w-full rounded bg-lightPrimary px-6 dark:!bg-navy-700 dark:text-white sm:overflow-auto"
          }
        >
          <div className="mt-1 overflow-x-auto">
            {noteList && noteList.length > 0 ? (
              <VerticalTimeline layout={"1-column-left"}>
                {noteList &&
                  noteList.map((op, i) => (
                    <VerticalTimelineElement
                      key={i}
                      className="vertical-timeline-element--work"
                      // contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                      // contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                      contentStyle={{
                        background: colorMode === "dark" ? "#1b254b" : "#fff",
                        color: colorMode === "dark" ? "#fff" : "#000",
                      }}
                      contentArrowStyle={{
                        borderRight: `7px solid ${
                          colorMode === "dark" ? "#1b254b" : "#fff"
                        }`,
                      }}
                      date={formatDateAmPm(op?.created_date)}
                      iconStyle={{
                        background: "#14bfff",
                        color: "#000",
                      }}
                      // icon={<WorkIcon />}
                      position={"right"}
                    >
                      <div>
                        <div>
                          <h3 className="text-md font-bold">
                            {op.first_name} {op.last_name}
                          </h3>
                          <p className="text-sm font-bold">
                            {op?.note_content
                              ? String(op.note_content)
                                  .replace(/\\n/g, " ")
                                  .replace(/\n/g, " ")
                              : ""}
                          </p>
                        </div>
                        {/* <div dangerouslySetInnerHTML={{ __html: op.additional_marketing_notes }} /> */}
                      </div>
                    </VerticalTimelineElement>
                  ))}
              </VerticalTimeline>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                {t("noRecordFound")}
              </div>
            )}
          </div>
        </div>
      )}
      <NoteAddModal
        isOpen={isAddNoteModalOpen}
        handleClose={handleAddNoteClose}
        setRefresh={setRefresh}
        refresh={refresh}
        contactId={contactId}
      />
    </div>
  );
};

export default ContactNotes;
