/* eslint-disable react-hooks/exhaustive-deps */
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import screen1 from "../../assets/img/onboarding/assistant.jpg";
import screen2 from "../../assets/img/onboarding/dialers.jpg";
import screen3 from "../../assets/img/onboarding/billing.jpg";
import screen4 from "../../assets/img/onboarding/segments.jpg";
import screen5 from "../../assets/img/onboarding/playground.jpg";
import screen6 from "../../assets/img/onboarding/campaigns.jpg";
import screen7 from "../../assets/img/onboarding/call_logs_review.jpg";

const OnboardingScreenModal = ({ isOpen, onClose }: any) => {
  const { t } = useTranslation();
  // const settings = {
  //   dots: true,
  //   dotsClass: "slick-dots slick-thumb",
  //   arrows: false,
  // };
  const settings = {
    dots: false,
    fade: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    // nextArrow: <NextArrow />, // Use the custom next arrow
    // prevArrow: <PrevArrow />, // Use the custom prev arrow
  };
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  return (
    <Modal
      isCentered
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={"xxl"}
      scrollBehavior={"inside"}
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent maxW={"1200px"} className="dark:bg-navy-900">
        <ModalHeader>{t("onboarding.title")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="px-6">
            <Slider {...settings}>
              <div>
                <div className="flex flex-col gap-3 text-center">
                  <h1 className="text-[24px] font-semibold">
                    {t("onboarding.screen1")}
                  </h1>
                  <p className="font-medium">{t("onboarding.screen1Cap")}</p>
                  <img
                    src={screen1}
                    alt="scrren1"
                    className="max-h-[70vh] w-full object-contain"
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-col gap-3 text-center">
                  <h1 className="text-[20px] font-semibold">
                    {t("onboarding.screen2")}
                  </h1>
                  <p className="font-medium">{t("onboarding.screen2Cap")}</p>
                  <img
                    src={screen2}
                    alt="scrren1"
                    className="max-h-[70vh] w-full object-contain"
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-col gap-3 text-center">
                  <h1 className="text-[20px] font-semibold">
                    {t("onboarding.screen3")}
                  </h1>
                  <p className="font-medium">{t("onboarding.screen3Cap")}</p>
                  <img
                    src={screen3}
                    alt="scrren1"
                    className="max-h-[70vh] w-full object-contain"
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-col gap-3 text-center">
                  <h1 className="text-[20px] font-semibold">
                    {t("onboarding.screen4")}
                  </h1>
                  <p className="font-medium">{t("onboarding.screen4Cap")}</p>
                  <img
                    src={screen4}
                    alt="scrren1"
                    className="max-h-[70vh] w-full object-contain"
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-col gap-3 text-center">
                  <h1 className="text-[20px] font-semibold">
                    {t("onboarding.screen8")}
                  </h1>
                  <p className="font-medium">{t("onboarding.screen8Cap")}</p>
                  <img
                    src={screen5}
                    alt="scrren1"
                    className="max-h-[70vh] w-full object-contain"
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-col gap-3 text-center">
                  <h1 className="text-[20px] font-semibold">
                    {t("onboarding.screen9")}
                  </h1>
                  <p className="font-medium">{t("onboarding.screen9Cap")}</p>
                  <img
                    src={screen6}
                    alt="scrren1"
                    className="max-h-[70vh] w-full object-contain"
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-col gap-3 text-center">
                  <h1 className="text-[20px] font-semibold">
                    {t("onboarding.screen10")}
                  </h1>
                  <p className="font-medium">{t("onboarding.screen10Cap")}</p>
                  <img
                    src={screen7}
                    alt="scrren1"
                    className="max-h-[70vh] w-full object-contain"
                  />
                </div>
              </div>
            </Slider>
            {/* <div className="flex justify-around">
            <Button className="button" onClick={previous}>
              <FcPrevious />
            </Button>
            <Button className="button" onClick={next}>
              <FcNext />
            </Button>
          </div> */}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default OnboardingScreenModal;
