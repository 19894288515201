/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import Card from "components/card";

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useLocation, useNavigate } from "react-router-dom";
import { getAudienceList } from "services/contact";
import { campaignFavourite, getCampaignAudienceList } from "services/campaign";

import { Tag, useToast } from "@chakra-ui/react";
import { FaArrowLeft, FaFlag, FaRegStar, FaStar } from "react-icons/fa";
import { TbRefresh } from "react-icons/tb";
import CampaignDetailModal from "components/modal/CampaignDetailModal";
import NewCpModal from "components/modal/NewCpModal";
import CallDurationTotal from "components/callDurationTotal";
import Loader from "components/Loader/Loader";
import TooltipHorizon from "components/tooltip";
import { formatDateAmPm } from "utils/utils";
import PlaygroundFeedBackModal from "components/modal/PlaygroundFeedBackModal";
import * as XLSX from "xlsx";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
type RowObj = {
  answered_by: any;
  full_name: string;
  phone: string;
  email: string;
  tech: any;
  date: string;
  progress: number;
  campaignName: string;
  customerName: string;
  sentiments: string;
  callDuration: string;
  status: string;
  error: string;
  actions: any;
  is_call_transferred: any;
  is_message_sent: any;
  feedback: any;
  hash_id: any;
  is_meeting_scheduled: any;
  is_voice_note: any;
  created_at: any;
  newStatus: any;
  is_email_sent: any;
  is_fav: any;
  is_whatsapp_sent: any;
};
let isStart = false;
function CampaignsDetailTable() {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const receivedData = location.state || {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [isNewCpModal, setIsNewCpModalOpen] = useState(false);
  // const [isStart, setIsStart] = useState(receivedData?.rowData?.is_started);
  const [refresh, setRefresh] = useState(false);
  const [sentiment, setSentiment] = useState("");
  const [callStatus, setCallStatus] = useState("");
  const [favourite, setFavourite] = useState("");

  const [allTableData, setAllTableData] = useState([]);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [isOpenRefreshConfirm, setIsOpenRefreshConfirm] = useState(false);
  const [callId, setCallId] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const fetchData = useCallback(() => {
    // const params = {
    //   id: receivedData?.rowData?.id,
    //   page: pagination?.pageIndex,
    //   perpage: pagination?.pageSize,
    //   status: callStatus,
    //   sentiment: sentiment,
    // };
    // fetchCampaignDetails(params);
    setTimeout(() => {
      isStart = receivedData?.rowData?.is_started;
      const params = {
        id: receivedData?.rowData?.id,
        page: pagination?.pageIndex,
        perpage: pagination?.pageSize,
        status: callStatus,
        sentiment: sentiment,
        favourite: favourite,
      };
      fetchCampaignDetails(params);
    }, 500);
  }, [
    receivedData,
    pagination?.pageIndex,
    pagination?.pageSize,
    callStatus,
    sentiment,
    favourite,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  useEffect(() => {
    if (refresh) {
      isStart = true;
      setTimeout(() => {
        fetchData();
      }, 1000);
    }
  }, [refresh]);
  const handleCloseModal = (rowData: any) => {
    setIsModalOpen(!isModalOpen);
  };
  const handleOpenModal = (rowData: any) => {
    // setRowData(rowData?.row?.original);
    setRowData(rowData.row.original);
    setIsModalOpen(!isModalOpen);
  };
  const handleFeedbackOpen = (id: any) => {
    setFeedbackModalOpen(true);
    setCallId(id);
  };
  const handleFeedbackClose = () => {
    setFeedbackModalOpen(false);
  };
  // useEffect(() => {
  //   if (receivedData) {
  //     setTimeout(() => {
  //       isStart = receivedData?.rowData?.is_started;
  //       const params = {
  //         id: receivedData?.rowData?.id,
  //         page: pagination?.pageIndex,
  //         perpage: pagination?.pageSize,
  //         status: callStatus,
  //         sentiment: sentiment,
  //       };
  //       fetchCampaignDetails(params);
  //     }, 1000);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [receivedData]);

  const infoData = allTableData
    .filter((item) => item.extracted_info)
    .map((item) => {
      if (item?.extracted_info) {
        try {
          const parsedInfo = JSON.parse(item.extracted_info);
          if (parsedInfo && Array.isArray(parsedInfo.questions)) {
            return {
              name: item.full_name,
              phoneNumber: item.phone,
              extracted_info: parsedInfo.questions.map((question: any) => ({
                type: question.type,
                question: question.question,
                options: question.options || [],
                answer: question.answer,
              })),
            };
          }
        } catch (e) {
          console.error("Failed to parse extracted_info:", e);
        }
      }
      return null;
    })
    .filter((item) => item !== null);

  // const filterData = (criteria: string, value: string) => {
  //   let updatedSentiment = sentiment;
  //   let updatedCallStatus = callStatus;

  //   if (criteria === "callStatus") {
  //     setCallStatus(value);
  //     updatedCallStatus = value;
  //   } else if (criteria === "sentiment") {
  //     setSentiment(value);
  //     updatedSentiment = value;
  //   }

  //   let filterTableData = allTableData;

  //   if (updatedCallStatus) {
  //     filterTableData = filterTableData.filter((item) => {
  //       if (updatedCallStatus === "completed") {
  //         return (
  //           item?.callsid &&
  //           item?.status === "true" &&
  //           Number(item?.call_duration_in_sec) > 0
  //         );
  //       } else if (updatedCallStatus === "inProgress") {
  //         return (
  //           item?.callsid &&
  //           item?.status === "false" &&
  //           Number(item?.call_duration_in_sec) === 0 &&
  //           timeStatus(item?.created_at, item?.maximum_time_per_call)
  //         );
  //       } else if (updatedCallStatus === "failed") {
  //         return (
  //           item?.callsid &&
  //           item?.status === "false" &&
  //           Number(item?.call_duration_in_sec) === 0 &&
  //           !timeStatus(item?.created_at, item?.maximum_time_per_call)
  //         );
  //       } else if (updatedCallStatus === "error") {
  //         return item?.error !== null;
  //       }
  //       return true;
  //     });
  //   }

  //   if (updatedSentiment) {
  //     filterTableData = filterTableData.filter((item) => {
  //       if (updatedSentiment === "positive") {
  //         return item?.call_sentiment?.includes("positive");
  //       } else if (updatedSentiment === "negative") {
  //         return item?.call_sentiment?.includes("negative");
  //       } else if (updatedSentiment === "neutral") {
  //         return (
  //           item?.call_sentiment &&
  //           !item?.call_sentiment?.includes("positive") &&
  //           !item?.call_sentiment?.includes("negative")
  //         );
  //       }
  //       return true;
  //     });
  //   }

  //   setTableData(filterTableData);
  // };

  const fetchCampaignDetails = (params: any) => {
    if (isStart) {
      setLoading(true);
      getCampaignAudienceList(params)
        .then((response) => {
          if (response.success) {
            if (response?.data) {
              for (let item of response?.data) {
                if (
                  item.answered_by !== "robot" &&
                  item?.callsid &&
                  item?.status === "true" &&
                  Number(item?.call_duration_in_sec) > 0
                ) {
                  item.newStatus = "Completed";
                }

                if (
                  item.answered_by !== "robot" &&
                  item?.callsid &&
                  item?.status === "false" &&
                  Number(item?.call_duration_in_sec) === 0
                ) {
                  if (
                    timeStatus(
                      item?.created_at,
                      Number(receivedData?.rowData?.maximum_time_per_call)
                    )
                  ) {
                    item.newStatus = "In Progress";
                  } else {
                    item.newStatus = "Failed";
                  }
                }
                if (
                  item.answered_by !== "robot" &&
                  item?.callsid &&
                  item?.status === "false" &&
                  Number(item?.call_duration_in_sec) > 0
                ) {
                  item.newStatus = "Failed";
                }
                if (
                  item.answered_by !== "robot" &&
                  !item?.callsid &&
                  item?.status === "false"
                ) {
                  item.newStatus = "Pending";
                }
                if (item.answered_by !== "robot" && item?.error !== null) {
                  item.newStatus = "Error";
                }
                if (item.answered_by === "robot" && item?.error !== null) {
                  if (!receivedData?.rowData?.is_voice_note) {
                    item.newStatus = "Failed";
                  } else {
                    item.newStatus = "Voice Note Sent";
                  }
                }
              }
            }
            const itemsWithFullName = response?.data.map((item: any) => ({
              ...item,
              full_name: `${item.first_name} ${item.last_name}`,
            }));
            setTableData(itemsWithFullName);
            setAllTableData(itemsWithFullName);
            setTotalPages(response?.totalPages);
            // handleConfirmClose();
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error submitting feedback:", error);
        });
    } else {
      setLoading(true);
      let newParam = {
        id: receivedData?.rowData?.segment_id,
        page: params?.page,
        perpage: params?.perpage,
        search: "",
      };
      getAudienceList(newParam)
        .then((response) => {
          if (response.success) {
            const itemsWithFullName = response?.data.map((item: any) => ({
              ...item,
              full_name: `${item.first_name} ${item.last_name}`,
            }));
            setTableData(itemsWithFullName);
            setAllTableData(itemsWithFullName);
            // handleConfirmClose();
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error submitting feedback:", error);
        });
    }
  };
  const timeStatus = (created_at: string, time_per_call: number) => {
    const createdAtDate = new Date(created_at);

    const calculatedTime = new Date(
      createdAtDate.getTime() + time_per_call * 60000
    );
    const currentTime = new Date();

    return currentTime.getTime() < calculatedTime.getTime();
  };

  const doFavourite = (item: any) => {
    let req = {
      id: item.hash_id,
      is_fav: !item.is_fav,
    };
    setLoading(true);
    campaignFavourite(req)
      .then((response) => {
        setLoading(false);
        if (response.success) {
          toast({
            description: item.is_fav
              ? t("messages.UNMARKED_FAV")
              : t("messages.MARKED_FAV"),
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        } else {
          toast({
            description: response?.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };
  const columns = [
    columnHelper.accessor("full_name", {
      id: "Name",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("campaign.name")}
        </p>
      ),
      cell: (info) => (
        <p
          onClick={() => handleOpenModal(info)}
          className="flex cursor-pointer items-center justify-end gap-2 text-blue-700 hover:underline sm:justify-center"
        >
          {info.row.original.full_name ? (
            <span>{info.row.original.full_name}</span>
          ) : (
            <span>-</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor("phone", {
      id: "Phone Number",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("campaign.phone")}
        </p>
      ),
      cell: (info) => (
        <p className="flex items-center justify-end gap-2 sm:justify-center">
          {info.row.original.phone ? (
            <span>{info.row.original.phone}</span>
          ) : (
            <span>-</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor("callDuration", {
      id: "call Duration",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("campaign.duration")}
        </p>
      ),
      cell: (info: any) => (
        <p className="flex items-center justify-end gap-2 sm:justify-center">
          {isStart ? (
            <span>
              {info.row.original.answered_by !== "robot" &&
              info.row.original?.newStatus !== "Failed" ? (
                <CallDurationTotal
                  totalDurationInSeconds={Number(
                    info.row.original?.call_duration_in_sec || 0
                  )}
                />
              ) : (
                <CallDurationTotal totalDurationInSeconds={Number(0)} />
              )}
            </span>
          ) : (
            // <CallDurationTotal
            //   totalDurationInSeconds={Number(
            //     info.row.original?.call_duration_in_sec || 0
            //   )}
            // />
            "NA"
          )}
        </p>
      ),
    }),
    columnHelper.accessor("created_at", {
      id: "Called At",
      header: () => (
        <p className="flex min-w-[200px] items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("campaign.calledAt")}
        </p>
      ),
      cell: (info: any) => (
        <p className="flex min-w-[200px] items-center justify-end gap-2 sm:justify-center">
          {info.row.original.created_at
            ? formatDateAmPm(info.row.original.created_at)
            : "-"}
        </p>
      ),
    }),
    columnHelper.accessor("status", {
      id: "Status",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("campaign.status")}
        </p>
      ),
      cell: (info: any) => (
        <p className="flex cursor-pointer items-center justify-end gap-2 rounded-l-full sm:justify-center">
          {/* {receivedData?.is_started ? info.row.original.status : "NA"} */}
          {isStart ? (
            <span>
              {info.row.original.answered_by !== "robot" &&
                info.row.original?.callsid &&
                info.row.original?.status === "true" &&
                Number(info.row.original?.call_duration_in_sec) > 0 && (
                  <Tag variant="solid" colorScheme="green">
                    {t("campaign.completed")}
                  </Tag>
                )}

              {info.row.original.answered_by !== "robot" &&
                info.row.original?.callsid &&
                info.row.original?.status === "false" &&
                Number(info.row.original?.call_duration_in_sec) === 0 && (
                  <span>
                    {timeStatus(
                      info.row.original?.created_at,
                      Number(receivedData?.rowData?.maximum_time_per_call)
                    ) ? (
                      <Tag variant="solid" colorScheme="blue">
                        {t("campaign.inProgress")}
                      </Tag>
                    ) : (
                      <Tag variant="solid" colorScheme="yellow">
                        {t("campaign.failed")}
                      </Tag>
                    )}
                  </span>
                )}
              {info.row.original.answered_by !== "robot" &&
                info.row.original?.callsid &&
                info.row.original?.status === "false" &&
                Number(info.row.original?.call_duration_in_sec) > 0 && (
                  <span>
                    <Tag variant="solid" colorScheme="yellow">
                      {t("campaign.failed")}
                    </Tag>
                  </span>
                )}
              {info.row.original.answered_by !== "robot" &&
                !info.row.original?.callsid &&
                info.row.original?.status === "false" && (
                  <span>
                    <Tag variant="solid" colorScheme="yellow">
                      {t("campaign.pending")}
                    </Tag>
                  </span>
                )}
              {info.row.original.answered_by !== "robot" &&
                info.row.original?.error !== null && (
                  <TooltipHorizon
                    trigger={
                      <Tag variant="solid" colorScheme="red">
                        {t("campaign.error")}
                      </Tag>
                    }
                    placement="bottom"
                    content={info.row.original?.error}
                    extra="rounded text-[11px]"
                  />
                )}
              {info.row.original.answered_by === "robot" && (
                <span>
                  {!receivedData?.rowData?.is_voice_note ? (
                    <Tag variant="solid" colorScheme="yellow">
                      {t("campaign.failed")}
                    </Tag>
                  ) : (
                    <Tag variant="solid">{t("campaign.voiceNoteSent")}</Tag>
                  )}
                </span>
              )}
            </span>
          ) : (
            "NA"
          )}
        </p>
      ),
    }),
    columnHelper.accessor("sentiments", {
      id: "Sentiments",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("campaign.sentiments")}
        </p>
      ),
      cell: (info: any) => (
        <p className="flex cursor-pointer items-center justify-end gap-2 rounded-l-full sm:justify-center">
          {/* {receivedData?.rowData?.is_started ? info.row.original.call_sentiment : ""} */}
          {info.row.original?.call_sentiment &&
          info.row.original?.answered_by !== "robot" &&
          info.row.original?.newStatus !== "Failed" ? (
            <span>
              {info.row.original?.call_sentiment?.includes("positive") ? (
                <Tag variant="solid" colorScheme="green">
                  {t("campaign.positive")}
                </Tag>
              ) : info.row.original?.call_sentiment?.includes("negative") ? (
                <Tag variant="solid" colorScheme="red">
                  {t("campaign.negative")}
                </Tag>
              ) : (
                <Tag variant="solid" colorScheme="blue">
                  {t("campaign.neutral")}
                </Tag>
              )}
            </span>
          ) : (
            <span>-</span>
          )}
        </p>
      ),
    }),

    columnHelper.accessor("actions", {
      id: "Actions",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("campaign.actions")}
        </p>
      ),
      cell: (info) => (
        <div className="gap-1 text-center">
          {info.row.original?.answered_by !== "robot" &&
          info.row.original?.newStatus !== "Failed" ? (
            <div>
              {info.row.original?.is_call_transferred && (
                <div className="p-1">
                  <Tag variant="solid">{t("campaign.callTransferred")}</Tag>
                </div>
              )}
              {info.row.original?.is_message_sent && (
                <div className="p-1">
                  <Tag variant="solid">{t("campaign.messageSent")}</Tag>
                </div>
              )}
              {info.row.original?.is_meeting_scheduled && (
                <div className="p-1">
                  <Tag variant="solid">{t("campaign.meetingScheduled")}</Tag>
                </div>
              )}
              {info.row.original?.is_email_sent && (
                <div className="p-1">
                  <Tag variant="solid">{t("campaign.emailSent")}</Tag>
                </div>
              )}
              {info.row.original?.is_whatsapp_sent && (
                <div className="p-1">
                  <Tag variant="solid">{t("campaign.wMessageSent")}</Tag>
                </div>
              )}
              {!info.row.original?.is_call_transferred &&
                !info.row.original?.is_message_sent &&
                !info.row.original?.is_whatsapp_sent &&
                !info.row.original?.is_meeting_scheduled &&
                !info.row.original?.is_email_sent && <span>-</span>}
            </div>
          ) : (
            <div>-</div>
          )}
        </div>
      ),
    }),
    columnHelper.accessor("feedback", {
      id: "Feedback",
      header: () => (
        <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
          {t("campaign.feedback")}
        </p>
      ),
      cell: (info) => (
        <p className="flex items-center justify-end gap-6 sm:justify-center">
          {receivedData?.rowData?.is_started ? (
            <button
              onClick={() => {
                handleFeedbackOpen(info?.row?.original?.hash_id);
                if (Array.isArray((window as any).dataLayer)) {
                  (window as any).dataLayer.push({
                    event: "Feedback",
                    buttonName: "Feedback ",
                  });
                }
              }}
              className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
            >
              <FaFlag />
            </button>
          ) : (
            <span>-</span>
          )}
          {isStart ? (
            <TooltipHorizon
              trigger={
                <span
                  className="flex h-fit cursor-pointer items-center justify-center  gap-2 px-[6px] py-[6px] text-base font-medium text-yellow-700 transition duration-200 "
                  onClick={() => {
                    doFavourite(info?.row?.original);
                    if (Array.isArray((window as any).dataLayer)) {
                      (window as any).dataLayer.push({
                        event: "Favourite",
                        buttonName: "Favourite",
                      });
                    }
                  }}
                >
                  {info?.row?.original?.is_fav ? <FaStar /> : <FaRegStar />}
                </span>
              }
              placement="bottom"
              content={
                info?.row?.original?.is_fav
                  ? t("campaign.favourite")
                  : t("campaign.nonFavourite")
              }
              extra="rounded text-[11px]"
            />
          ) : (
            "-"
          )}
        </p>
      ),
    }),
  ];
  const exportData = () => {
    const tableD = tableData || [];
    for (let item of tableD) {
      if (item?.callsid && item?.status === "true") {
        item.callStatus = "Completed";
      }
      if (
        item?.callsid &&
        item?.status === "false" &&
        Number(item?.call_duration_in_sec) === 0
      ) {
        if (
          timeStatus(
            item?.created_at,
            Number(receivedData?.rowData?.maximum_time_per_call)
          )
        ) {
          item.callStatus = "In Progress";
        } else {
          item.callStatus = "Failed";
        }
      }
      if (
        item?.callsid &&
        item?.status === "false" &&
        Number(item?.call_duration_in_sec) > 0
      ) {
        item.callStatus = "Failed";
      }
      if (item?.error !== null) {
        item.callStatus = "Error";
      }
    }
    const headers = ["first_name", "last_name", "phone", "status"];

    const data = tableD.map((item: any) => [
      item?.first_name,
      item?.last_name,
      item?.phone,
      item?.callStatus,
    ]);
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${receivedData?.rowData?.campaigns_name}.csv`, {
      bookType: "csv",
    });
  };
  const table = useReactTable({
    data: tableData,
    columns: columns,
    state: {
      pagination,
      columnFilters: [
        { id: "status", value: callStatus },
        { id: "sentiment", value: sentiment },
      ],
    },
    pageCount: totalPages,
    manualPagination: true,
    manualFiltering: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });
  const refreshPage = () => {
    if (sentiment !== "" || callStatus !== "") {
      setIsOpenRefreshConfirm(true);
    } else {
      const params = {
        id: receivedData?.rowData?.id,
        page: pagination?.pageIndex,
        perpage: pagination?.pageSize,
        status: callStatus,
        sentiment: sentiment,
        favourite: favourite,
      };
      fetchCampaignDetails(params);
    }
  };
  const handleNewCpModal = () => {
    setIsNewCpModalOpen(!isNewCpModal);
  };
  const handleNewCpClose = () => {
    setIsNewCpModalOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCloseRefreshConfirm = () => {
    setIsOpenRefreshConfirm(false);
  };
  const refreshConfirmDone = () => {
    setIsOpenRefreshConfirm(false);
    setSentiment("");
    setCallStatus("");
    const params = {
      id: receivedData?.rowData?.id,
      page: pagination?.pageIndex,
      perpage: pagination?.pageSize,
      status: callStatus,
      sentiment: sentiment,
      favourite: favourite,
    };
    fetchCampaignDetails(params);
  };
  return (
    <div>
      {loading && <Loader />}
      <div className="mb-2">
        <div className="mb-3 mt-4 flex justify-end gap-2">
          {receivedData?.is_started && (
            <button
              onClick={() => navigate("/admin/campaigns")}
              className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
            >
              <FaArrowLeft className="h-4 w-4" />
              {t("goBack")}
            </button>
          )}
        </div>
        <div className="z-20 mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
          <Card
            extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white`}
            price="0.7"
          >
            <div className="flex h-full w-full  flex-col items-center justify-center">
              <h1 className="text-3xl font-bold">{tableData.length}</h1>
              <p className="text-xl ">{t("campaign.totalCallPlaced")}</p>
            </div>
          </Card>
          <Card
            extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white`}
            price="0.7"
          >
            <div className="flex h-full w-full  flex-col items-center justify-center">
              <h1 className="text-3xl font-bold">
                {
                  tableData.filter(
                    (item) => item.status === "true" && item.callsid !== null
                  ).length
                }
              </h1>
              <p className="text-xl ">{t("campaign.totalCallAnswered")}</p>
            </div>
          </Card>
          <Card
            extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white`}
            price="0.7"
          >
            <div className="flex h-full w-full  flex-col items-center justify-center">
              <h1 className="text-3xl font-bold">
                <CallDurationTotal
                  totalDurationInSeconds={tableData?.reduce(
                    (total, call) =>
                      total +
                      Number(
                        call?.call_duration_in_sec
                          ? call?.call_duration_in_sec
                          : 0
                      ),
                    0
                  )}
                />
              </h1>
              <p className="text-xl ">{t("campaign.totalVoiceConsumed")}</p>
            </div>
          </Card>
        </div>
        <div className="mb-3 mt-4 flex justify-between gap-2">
          <div>
            <button
              onClick={() => navigate("/admin/campaigns")}
              className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
            >
              <FaArrowLeft className="h-4 w-4" />
              {t("goBack")}
            </button>
          </div>
          <div className="flex flex-wrap justify-end gap-2">
            <select
              value={sentiment}
              onChange={(e) => setSentiment(e.target.value)}
              className="w-fit rounded border-[1px] border-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
            >
              <option value="all">{t("campaign.allSentiments")}</option>
              <option value={"positive"}>{t("campaign.positive")}</option>
              <option value={"negative"}>{t("campaign.negative")}</option>
              <option value={"neutral"}>{t("campaign.neutral")}</option>
            </select>
            <select
              value={callStatus}
              onChange={(e) => setCallStatus(e.target.value)}
              className="w-fit rounded border-[1px] border-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
            >
              <option value="all">{t("campaign.allCallStatus")}</option>
              <option value={"completed"}>{t("campaign.completed")}</option>
              <option value={"inProgress"}>{t("campaign.inProgress")}</option>
              <option value={"failed"}>{t("campaign.failed")}</option>
              <option value={"error"}>{t("campaign.error")}</option>
            </select>
            <select
              value={favourite}
              onChange={(e) => setFavourite(e.target.value)}
              className="w-fit rounded border-[1px] border-gray-200 px-4 
  py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
            >
              <option value="">{t("campaign.all")}</option>
              <option value={"true"}>{t("campaign.favourite")}</option>
              <option value={"false"}>{t("campaign.nonFavourite")}</option>
            </select>
            {!receivedData?.rowData?.is_started &&
              receivedData?.rowData?.scheduled_at && (
                <button
                  disabled
                  className="flex items-center justify-center gap-2 rounded border border-yellow-500 bg-white px-5 py-2 text-yellow-500 dark:border-blue-800 dark:bg-navy-900 dark:text-blue-800"
                >
                  {t("campaign.scheduledAt")}
                  {formatDateAmPm(receivedData?.rowData?.scheduled_at)}
                </button>
              )}
            {!receivedData?.rowData?.is_started &&
              !receivedData?.rowData?.scheduled_at && (
                <button
                  onClick={() => handleNewCpModal()}
                  className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
                >
                  {t("campaign.startCall")}
                </button>
              )}
            {!receivedData?.rowData?.is_started &&
              receivedData?.rowData?.scheduled_at && (
                <button
                  onClick={() => handleNewCpModal()}
                  className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
                >
                  {t("campaign.startImmediately")}
                </button>
              )}
            {infoData && infoData?.length > 0 && (
              <button
                onClick={() =>
                  navigate("/admin/campaigns-detail/info", {
                    state: {
                      rowData: receivedData?.rowData,
                      tableData: infoData,
                    },
                  })
                }
                className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
              >
                {t("campaign.seeInfo")}
              </button>
            )}
            {/* {
              (!receivedData?.rowData?.is_started) &&
              <button className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800">
                SCHEDULE CALL
              </button>
            } */}
            {receivedData?.rowData?.is_started && (
              <button
                className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
                onClick={() => exportData()}
                disabled={tableData?.length === 0}
              >
                <IoCloudDownloadOutline className="h-6 w-6" />
                {t("campaign.exportCsv")}
              </button>
            )}
            {isStart && (
              <button
                className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
                onClick={() => refreshPage()}
              >
                <TbRefresh /> {t("campaign.refresh")}
              </button>
            )}
          </div>
        </div>
      </div>
      <Card extra={"w-full sm:overflow-auto px-6"}>
        <div className="mt-1 overflow-x-auto">
          <table className="responsive-table w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      scope="col"
                      className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell: any) => {
                    const columnHeader = cell.column.id;
                    return (
                      <td
                        key={cell.id}
                        className="min-w-[150px] border-white/0 py-3 pr-4"
                        data-label={columnHeader}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
              {table.getRowModel().rows.length === 0 && (
                <tr>
                  <td
                    colSpan={table.getAllLeafColumns().length}
                    className="py-10 text-center"
                  >
                    {t("campaign.noData")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <CampaignDetailModal
            isOpen={isModalOpen}
            handleClose={handleCloseModal}
            rowData={rowData}
            campaignData={receivedData?.rowData}
            isStart={isStart}
            timeStatus={timeStatus}
          />
          <NewCpModal
            isOpen={isNewCpModal}
            handleClose={handleNewCpClose}
            setRefresh={setRefresh}
            refresh={refresh}
            rowData={receivedData?.rowData?.id}
            campaignsData={receivedData?.rowData}
          />
          <PlaygroundFeedBackModal
            onClose={handleFeedbackClose}
            isOpen={feedbackModalOpen}
            call_type={"campaign"}
            call_id={callId}
          />

          <Modal
            isCentered
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpenRefreshConfirm}
            onClose={handleCloseRefreshConfirm}
            scrollBehavior={"inside"}
            blockScrollOnMount={false}
          >
            <ModalOverlay />
            <ModalContent className="dark:bg-navy-900">
              <ModalHeader className="text-center">
                {t("confirmation")}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <div className="mt-6 flex flex-col items-center justify-center">
                  <p className="mb-4 text-[#000000]  dark:text-white">
                    {t("campaign.refreshText")}
                  </p>
                </div>
              </ModalBody>

              <ModalFooter>
                <Button
                  onClick={() => {
                    refreshConfirmDone();
                    handleCloseRefreshConfirm();
                  }}
                  colorScheme="blue"
                  mr={3}
                  className="dark:bg-blue-700 dark:text-white"
                >
                  {t("okay")}
                </Button>
                <Button onClick={handleCloseRefreshConfirm}>
                  {t("cancel")}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
        <div className="mb-2 mt-2 flex flex-wrap items-center justify-start gap-2">
          <button
            className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
            onClick={() => table.setPageIndex(0)}
            disabled={pagination?.pageIndex === 0}
          >
            {"<<"}
          </button>
          <button
            className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <div className="flex items-center gap-2">
            <span>
              {t("page")} {table.getState().pagination.pageIndex + 1} {t("of")}{" "}
              {totalPages}
            </span>
            <input
              type="number"
              min="1"
              max={totalPages}
              value={table.getState().pagination.pageIndex + 1}
              onChange={(e) => table.setPageIndex(Number(e.target.value) - 1)}
              className="w-[60px] rounded border border-gray-300 bg-gray-50 pl-2 text-base font-medium text-gray-700 dark:bg-navy-900 dark:text-white"
            />
            <button
              className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {">"}
            </button>
            <button
              className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
              onClick={() => table.setPageIndex(totalPages - 1)}
              disabled={!table.getCanNextPage()}
            >
              {">>"}
            </button>
          </div>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      </Card>
    </div>
  );
}

export default CampaignsDetailTable;
const columnHelper = createColumnHelper<RowObj>();
