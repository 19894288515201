/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Tooltip,
} from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import { useTranslation } from "react-i18next";
import { textVoiceSpeed } from "services/assitant";
import WavePlayer from "components/waveSurferPlayer/WaveSurferPlayer";
const GenerateTestVoiceSpeedModal = ({
  isOpen,
  onClose,
  formik,
  selectedVoiceData,
}: any) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { t } = useTranslation();
  const [generatedVoice, setGeneratedVoice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState<number | null>(
    null
  );

  const validationSchema = Yup.object().shape({
    testText: Yup.string().required(t("voiceTextValid")),
  });

  const formik1 = useFormik({
    initialValues: {
      testText: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      const payload = {
        text: values?.testText,
        speed: formik?.values?.voice_speed,
        voice_ai_modal: selectedVoiceData?.ai_modal,
        voice_name: selectedVoiceData?.name,
      };
      setIsLoading(true);
      textVoiceSpeed(payload)
        .then((response) => {
          setIsLoading(false);
          if (response.success) {
            let resData = response?.path?.file;
            setGeneratedVoice(resData);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error:", error);
        });
      //   closeTestVoice();
    },
  });

  const closeTestVoice = () => {
    formik1.resetForm();
    onClose();
    setGeneratedVoice(null);
  };
  useEffect(() => {
    if (isOpen) {
      let genText = `Hello my name is ${selectedVoiceData?.displayname}, Do you like my voice?`;
      formik1.setFieldValue("testText", genText);
    }
  }, [isOpen]);
  return (
    <>
      {isLoading && <Loader />}
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          closeTestVoice();
        }}
        size={"xxl"}
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent maxW={"700px"} className="dark:bg-navy-900">
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <div>
              <div className="mb-3 text-center text-xl font-bold dark:text-white">
                {t("testVoiceSpeed")}
              </div>
              <div>
                <div className="text-md mb-8 mt-5 flex w-full  items-center gap-3 font-medium dark:text-white">
                  <Slider
                    aria-label="slider-ex-1"
                    min={0}
                    max={2}
                    step={0.1}
                    className="w-full"
                    id="voice_speed"
                    name="voice_speed"
                    value={formik.values.voice_speed}
                    onChange={(value) =>
                      formik.setFieldValue("voice_speed", value)
                    }
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    isDisabled={selectedVoiceData?.ai_modal === "deepgram"}
                  >
                    <SliderMark
                      value={0}
                      mt="2"
                      ml="3.5"
                      fontSize="sm"
                      transform="translateX(-50%)"
                    >
                      {t("assistantForm.speedSlow")}
                    </SliderMark>
                    <SliderMark
                      value={2}
                      mt="3"
                      ml="-2.5"
                      fontSize="sm"
                      transform="translateX(-50%)"
                    >
                      {t("assistantForm.speedFast")}
                    </SliderMark>
                    <SliderTrack bg="blue.200">
                      <SliderFilledTrack />
                    </SliderTrack>
                    <Tooltip
                      hasArrow
                      bg="blue.500"
                      color="white"
                      placement="top"
                      isOpen={showTooltip}
                      label={formik.values.voice_speed}
                    >
                      <SliderThumb />
                    </Tooltip>
                  </Slider>
                  <input
                    className="inline-flex w-[50px] rounded border border-gray-500 bg-white px-2 py-1 text-center text-sm font-medium focus:border-blue-500 focus:outline-none focus:ring-blue-700 dark:bg-navy-900"
                    type="text"
                    id="voice_speed"
                    value={formik?.values.voice_speed}
                    disabled
                    onChange={(e) =>
                      formik.setFieldValue("voice_speed", e.target.value)
                    }
                  />
                </div>
              </div>
              <FormControl>
                <FormLabel>{t("voiceText")} *</FormLabel>
                <input
                  className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                  id="testText"
                  name="testText"
                  type="text"
                  placeholder=""
                  value={formik1.values.testText}
                  onChange={formik1.handleChange}
                />
                {formik1.touched.testText && formik1.errors.testText ? (
                  <div className="text-red-500">
                    {formik1.errors.testText.toString()}
                  </div>
                ) : null}
              </FormControl>
              <div className="mt-4">
                <Button
                  colorScheme="blue"
                  type="submit"
                  mr={3}
                  onClick={() => {
                    setGeneratedVoice(null);
                    formik1.handleSubmit();
                  }}
                  className="dark:bg-blue-700 dark:text-white"
                >
                  {t("generateTestVoice")}
                </Button>
              </div>
              {generatedVoice && (
                <div className="mb-3 mt-5 w-full">
                  <WavePlayer
                    audio={process.env.REACT_APP_URL_FOR_AUDIO + generatedVoice}
                    index={0}
                    currentPlayingIndex={currentPlayingIndex}
                    setCurrentPlayingIndex={setCurrentPlayingIndex}
                  />
                </div>
              )}
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              type="submit"
              mr={3}
              onClick={() => closeTestVoice()}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("cancel")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GenerateTestVoiceSpeedModal;
