/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Switch,
  Tooltip,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import GenderAccentModal from "components/modal/GenderAccentModal";
import GenerateTestVoiceSpeedModal from "components/modal/GenerateTestVoiceSpeedModal";
import TooltipHorizon from "components/tooltip";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FaAngleDown,
  FaArrowLeft,
  FaInfoCircle,
  FaMicrophone,
  FaPauseCircle,
  FaPlayCircle,
} from "react-icons/fa";
// import { MdClose } from "react-icons/md";
const StepTwo = ({
  formik,
  handleNextStep,
  handlePrevStep,
  voiceList,
  selectedGenderData,
  setSelectedGenderData,
}: any) => {
  const { t } = useTranslation();
  const soundUrl = process.env.REACT_APP_URL_FOR_AUDIO + "bg-sound/office.wav";
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [isGenderModalOpen, setIsGenderModalOpen] = useState(false);
  const audioRef = React.createRef<HTMLAudioElement>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isVoiceDisable, setIsVoiceDisable] = useState(false);
  const [isOpenVoiceTestModal, setIsOpenVoiceTestModal] = useState(false);

  // const [currentFiller, setCurrentFiller] = useState("");

  const handleNext = () => {
    handleNextStep();
  };
  const handlePrev = () => {
    handlePrevStep();
  };
  const handleGenderClose = () => {
    setIsGenderModalOpen(false);
  };
  const openSelectVoice = () => {
    setIsGenderModalOpen(true);
  };
  const handlePlayPause = () => {
    if (isPlaying) {
      (audioRef.current as HTMLAudioElement).pause();
      setIsPlaying(false);
    } else {
      (audioRef.current as HTMLAudioElement).play();
      setIsPlaying(true);
    }
  };
  const handleSoundOptionChange = (event: any) => {
    formik.setFieldValue("is_back_sound", event.target.value);
    if (event.target.value === "off") {
      audioRef.current?.pause();
      setIsPlaying(false);
    }
  };
  const handleChangeIsVoiceNote = (e: any) => {
    if (!e.target.checked) {
      setIsVoiceDisable(true);
    } else {
      formik.setFieldValue("is_voice_note", e.target.checked);
    }
  };
  const handleOkVoiceConfirm = () => {
    setIsVoiceDisable(false);
    formik.setFieldValue("is_voice_note", false);
  };
  const handleCloseVoiceDisable = () => {
    setIsVoiceDisable(false);
  };
  const openVoiceTestModal = () => {
    if (formik?.values?.voice_name) {
      setIsOpenVoiceTestModal(true);
    }
  };
  const handleCloseVoiceTest = () => {
    setIsOpenVoiceTestModal(false);
  };
  // const handleAddChip = (event: any) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //     const newChip = event.target.value.trim();
  //     if (newChip && !formik.values.fillers.includes(newChip)) {
  //       formik.setFieldValue("fillers", [...formik.values.fillers, newChip]);
  //       event.target.value = ""; // Clear input
  //       setCurrentFiller("");
  //     }
  //   }
  // };

  // const handleDelete = (chipToDelete: any) => {
  //   formik.setFieldValue(
  //     "fillers",
  //     formik.values.fillers.filter((chip: any) => chip !== chipToDelete)
  //   );
  // };
  // const handleInputChange = (event: any) => {
  //   setCurrentFiller(event.target.value);
  // };
  return (
    <div className="m-auto max-w-[900px]">
      <div className="flex flex-col gap-3 lg:flex-row">
        <div className="flex w-full flex-col  gap-1 xl:w-[50%]">
          <div className="flex items-center gap-2">
            <span className="text-md font-medium  dark:text-white">
              {t("assistantForm.chooseAccent")} *
            </span>
          </div>
          <Box position="relative">
            <Flex
              onClick={openSelectVoice}
              cursor="pointer"
              borderWidth="1px"
              borderColor="gray.300"
              borderRadius="md"
              height={50}
              p="2"
              _hover={{ bg: "gray.100" }}
              align="center"
              justifyContent={"space-between"}
              backgroundColor={"#fff"}
              className={" dark:bg-navy-900 hover:dark:bg-navy-900"}
              style={{
                opacity: 1,
                borderColor: "#adb5bd",
              }}
              // className="h-[50px] rounded border border-gray-500 pl-4
              // focus:border-blue-500 focus:outline-none focus:ring-blue-700 dark:bg-navy-900 dark:hover:border-[#fff]"
            >
              <Text mr="2">
                {selectedGenderData
                  ? selectedGenderData?.displayname
                  : t("assistantForm.selectChooseAccent")}
              </Text>
              <FaAngleDown />
            </Flex>
          </Box>
          {formik.touched.voice_name && formik.errors.voice_name ? (
            <div className="text-red-500">
              {formik.errors.voice_name.toString()}
            </div>
          ) : null}
        </div>
        <div className="relative flex w-full flex-col gap-1 xxl:w-[50%]">
          <label
            className="whitespace-nowrap dark:text-white"
            htmlFor="companyName"
          >
            {t("assistantForm.MaximumDuration")} *
          </label>
          <input
            id="maximum_time_per_call"
            name="maximum_time_per_call"
            value={formik?.values.maximum_time_per_call}
            placeholder={t("assistantForm.MaximumDurationPlaceholder")}
            onChange={formik.handleChange}
            type="number"
            className="h-[50px] rounded border border-gray-500 pl-4  
                focus:border-blue-500 focus:outline-none focus:ring-blue-700 dark:bg-navy-900"
          />
          {formik.touched.maximum_time_per_call &&
          formik.errors.maximum_time_per_call ? (
            <div className="text-red-500">
              {formik.errors.maximum_time_per_call.toString()}
            </div>
          ) : null}
        </div>
      </div>
      <div className="mb-4 mt-4 block items-center justify-between gap-2 2xl:flex">
        <div className="text-md mt-3 flex items-center font-medium dark:text-white 2xl:block">
          <span className="flex items-center gap-2">
            {t("assistantForm.speedText")}{" "}
            {selectedGenderData?.ai_modal !== "deepgram" && (
              <span
                className="flex cursor-pointer items-center text-blue-700"
                onClick={() => openVoiceTestModal()}
              >
                (<FaMicrophone /> {t("generateTestVoice")})
              </span>
            )}
          </span>
          <div className="text-sm font-light">
            {t("assistantForm.speedOutputText")}
          </div>
        </div>
        <div className="text-md mt-3 flex w-full max-w-[350px]  items-center gap-3 font-medium dark:text-white">
          <Slider
            aria-label="slider-ex-1"
            min={0}
            max={2}
            step={0.1}
            className="w-full"
            id="voice_speed"
            name="voice_speed"
            value={formik.values.voice_speed}
            onChange={(value) => formik.setFieldValue("voice_speed", value)}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            isDisabled={selectedGenderData?.ai_modal === "deepgram"}
          >
            <SliderMark
              value={0}
              mt="2"
              ml="3.5"
              fontSize="sm"
              transform="translateX(-50%)"
            >
              {t("assistantForm.speedSlow")}
            </SliderMark>
            <SliderMark
              value={2}
              mt="3"
              ml="-2.5"
              fontSize="sm"
              transform="translateX(-50%)"
            >
              {t("assistantForm.speedFast")}
            </SliderMark>
            <SliderTrack bg="blue.200">
              <SliderFilledTrack />
            </SliderTrack>
            <Tooltip
              hasArrow
              bg="blue.500"
              color="white"
              placement="top"
              isOpen={showTooltip}
              label={formik.values.voice_speed}
            >
              <SliderThumb />
            </Tooltip>
          </Slider>
          <input
            className="inline-flex w-[50px] rounded border border-gray-500 bg-white px-2 py-1 text-center text-sm font-medium focus:border-blue-500 focus:outline-none focus:ring-blue-700 dark:bg-navy-900"
            type="text"
            id="voice_speed"
            value={formik?.values.voice_speed}
            disabled
            onChange={(e) =>
              formik.setFieldValue("voice_speed", e.target.value)
            }
          />
        </div>
      </div>

      <div className="mb-5 mt-5 flex items-center justify-between gap-2">
        <span className="text-md flex items-center font-medium  dark:text-white">
          {t("assistantForm.recordingText")}{" "}
          <TooltipHorizon
            trigger={
              <span>
                <FaInfoCircle className="ml-3 cursor-pointer text-blue-700" />
              </span>
            }
            placement="top"
            content={t("assistantForm.recordingTooltipText")}
            extra="rounded text-[11px]"
          />
        </span>
        <span className="text-md font-medium  dark:text-white">
          <Switch
            id="recording"
            sx={{
              "& input:checked ~ .chakra-switch__track": {
                bg: "#14bfff",
              },
              "& input:checked ~ .chakra-switch__thumb": {
                bg: "#14bfff",
              },
            }}
            size="md"
            onChange={formik.handleChange}
            isChecked={formik.values.recording}
          />
        </span>
      </div>
      <div className="mb-5 mt-5 flex items-center justify-between gap-2">
        <span className="text-md flex items-center font-medium  dark:text-white">
          {t("assistantForm.backgroundSoundText")}
        </span>
        <span className="text-md flex items-center gap-3  font-medium dark:text-white">
          <select
            className="inline-flex w-full rounded border border-gray-500 bg-white px-4 py-3 text-sm font-medium focus:border-blue-500 focus:outline-none focus:ring-blue-700 dark:bg-navy-900"
            id="is_back_sound"
            name="is_back_sound"
            onChange={handleSoundOptionChange}
            value={formik.values.is_back_sound}
          >
            <option value="office">
              {t("assistantForm.backgroundSoundOn")}
            </option>
            <option value="off">{t("assistantForm.backgroundSoundOff")}</option>
          </select>
          {formik.values.is_back_sound === "office" && (
            <div>
              <span className="cursor-pointer" onClick={handlePlayPause}>
                {isPlaying ? (
                  <FaPauseCircle className="h-5 w-5" />
                ) : (
                  <FaPlayCircle className="h-5 w-5" />
                )}
              </span>
              <audio ref={audioRef} src={soundUrl} hidden />
            </div>
          )}
        </span>
      </div>
      {/* <div className="mb-2 mt-5 flex items-center justify-between gap-2">
        <span className="text-md flex items-center font-medium  dark:text-white">
          {t("assistantForm.fillers")}{" "}
          <TooltipHorizon
            trigger={
              <span>
                <FaInfoCircle className="ml-3 cursor-pointer text-blue-700" />
              </span>
            }
            placement="top"
            content={t("assistantForm.fillersTooltip")}
            extra="rounded text-[11px]"
          />
        </span>
        <span className="text-md font-medium  dark:text-white">
          <Switch
            id="is_filler"
            sx={{
              "& input:checked ~ .chakra-switch__track": {
                bg: "#14bfff",
              },
              "& input:checked ~ .chakra-switch__thumb": {
                bg: "#14bfff",
              },
            }}
            size="md"
            onChange={formik.handleChange}
            isChecked={formik.values.is_filler}
          />
        </span>
      </div>
      <div>
        <div className="relative mb-5">
          <input
            type="text"
            id="fillers"
            name="fillers"
            value={currentFiller}
            onChange={handleInputChange}
            onKeyDown={handleAddChip}
            placeholder={t("assistantForm.fillersPlaceholder")}
            className="h-[50px] w-full rounded border border-gray-500 px-4  
                focus:border-blue-500 focus:outline-none focus:ring-blue-700 dark:bg-navy-900"
            disabled={!formik.values.is_filler}
          />
          <div className="mt-2 flex flex-wrap gap-2">
            {formik.values.fillers.map((chip: any, index: any) => (
              <div
                key={index}
                className="flex items-center space-x-2 rounded bg-gray-200 px-2 py-1 text-gray-800"
              >
                <span>{chip}</span>
                <span
                  className={` ${
                    !formik.values.is_filler
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  }`}
                >
                  <MdClose
                    className="w-5"
                    onClick={() =>
                      !formik.values.is_filler ? null : handleDelete(chip)
                    }
                  />
                </span>
              </div>
            ))}
          </div>
        </div>
        {formik.touched.fillers && formik.errors.fillers ? (
          <div className="text-red-500">{formik.errors.fillers.toString()}</div>
        ) : null}
      </div> */}
      {formik.values.type === "outbound" && (
        <div className="relative flex w-full flex-col gap-1">
          <div className="flex items-center justify-between gap-2">
            <span className="text-md flex items-center font-medium  dark:text-white">
              {t("assistantForm.voiceNoteText")}
              <TooltipHorizon
                trigger={
                  <span>
                    <FaInfoCircle className="ml-3 cursor-pointer text-blue-700" />
                  </span>
                }
                placement="top"
                content={t("assistantForm.voiceNoteTooltipText")}
                extra="rounded text-[11px]"
              />
            </span>
            <span className="text-md font-medium  dark:text-white">
              <Switch
                id="is_voice_note"
                sx={{
                  "& input:checked ~ .chakra-switch__track": {
                    bg: "#14bfff",
                  },
                  "& input:checked ~ .chakra-switch__thumb": {
                    bg: "#14bfff",
                  },
                }}
                size="md"
                isChecked={formik.values.is_voice_note}
                onChange={handleChangeIsVoiceNote}
              />
            </span>
          </div>
          <input
            id="voice_note"
            name="voice_note"
            type="text"
            value={formik?.values.voice_note}
            placeholder={t("assistantForm.voiceNotePlaceholderText")}
            onChange={(e) => {
              formik.setFieldValue("voice_note", e.target.value);
            }}
            maxLength={255}
            disabled={!formik?.values.is_voice_note}
            className="h-[50px] rounded border border-gray-500 px-4  
                focus:border-blue-500 focus:outline-none focus:ring-blue-700 dark:bg-navy-900"
          />
          {formik.touched.voice_note && formik.errors.voice_note ? (
            <div className="text-red-500">
              {formik.errors.voice_note.toString()}
            </div>
          ) : null}
        </div>
      )}
      <div className="mt-8 flex justify-center gap-[20px]">
        <Button onClick={() => handlePrev()} gap={3}>
          <FaArrowLeft /> {t("assistantForm.backStep")}
        </Button>
        <Button
          onClick={() => handleNext()}
          isDisabled={
            !formik.values.maximum_time_per_call ||
            !formik.values.voice_name ||
            (formik.values.type === "outbound"
              ? formik.values.is_voice_note
                ? !formik.values.voice_note
                : false
              : false) ||
            (formik.values.is_filler
              ? formik.values.fillers.length === 0
              : false)
          }
          colorScheme="blue"
          className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
        >
          {t("assistantForm.nextStep")}
        </Button>
      </div>
      <GenderAccentModal
        onClose={handleGenderClose}
        isOpen={isGenderModalOpen}
        voiceList={voiceList}
        selectedVoiceData={selectedGenderData}
        setSelectedVoiceData={setSelectedGenderData}
        selectedLanguage={formik.values.language}
      />
      <GenerateTestVoiceSpeedModal
        onClose={handleCloseVoiceTest}
        isOpen={isOpenVoiceTestModal}
        formik={formik}
        selectedVoiceData={selectedGenderData}
      />
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isVoiceDisable}
        onClose={handleCloseVoiceDisable}
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent className="dark:bg-navy-900">
          <ModalCloseButton />
          <ModalBody pb={6}>
            <div className="mt-6 flex flex-col items-center justify-center">
              <p className="mt-4 text-[18px] text-[#000000]  dark:text-white">
                {t("assistantForm.voiceNoteDisabled")}
              </p>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                handleOkVoiceConfirm();
              }}
              colorScheme="blue"
              mr={3}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("ok")}
            </Button>
            <Button onClick={handleCloseVoiceDisable}>{t("cancel")}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default StepTwo;
