/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import SaveConfirmModal from "./SaveConfirmModal";
import { CreateNote } from "services/crm";
import Loader from "components/Loader/Loader";
import { useTranslation } from "react-i18next";

const NoteAddModal = ({
  isOpen,
  handleClose,
  setRefresh,
  refresh,
  contactId,
}: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  // const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenWarning, setIsOpenWarning] = useState(false);

  const validationSchema = Yup.object().shape({
    noteContent: Yup.string().required(t("contactNotes.noteValidation")),
  });
  const formik = useFormik({
    initialValues: {
      noteContent: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      let payload = {
        note_content: values?.noteContent?.replace(/\n/g, " "),
      };
      setIsLoading(true);
      CreateNote(payload, contactId)
        .then((response: any) => {
          setIsLoading(false);
          if (response.success) {
            toast({
              description: t("messages.noteCreated"),
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            handleClose();
            setRefresh(!refresh);
            resetForm();
          } else {
            toast({
              description: response.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error:", error);
        });
    },
  });
  const checkIsDirtyForm = () => {
    let isDirty = formik.dirty && formik.submitCount === 0;
    if (isDirty) {
      openWarningModal();
    } else {
      formik.resetForm();
      handleClose();
    }
  };
  const openWarningModal = () => {
    setIsOpenWarning(true);
  };
  const closeWarningModal = () => {
    setIsOpenWarning(false);
  };
  const onConfirm = () => {
    handleClose();
    formik.resetForm();
    setIsOpenWarning(false);
  };
  const onCancel = () => {
    setIsOpenWarning(false);
  };

  return (
    <>
      {/* {loading && <Loader />} */}
      <Drawer
        placement="right"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={checkIsDirtyForm}
        // scrollBehavior={"inside"}
        blockScrollOnMount={false}
        size={"sm"}
      >
        {isLoading && <Loader />}
        <DrawerOverlay />
        <DrawerContent className="dark:bg-navy-900">
          <DrawerHeader>{t("contactNotes.addNote")}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody pb={6}>
            <FormControl mt={4}>
              <FormLabel>{t("contactNotes.note")}*</FormLabel>
              <textarea
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="noteContent"
                name="noteContent"
                value={formik.values.noteContent}
                onChange={formik.handleChange}
                rows={5}
              ></textarea>
              {formik.touched.noteContent && formik.errors.noteContent ? (
                <div className="text-red-500">
                  {formik.errors.noteContent.toString()}
                </div>
              ) : null}
            </FormControl>
          </DrawerBody>

          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("save")}
            </Button>
            <Button onClick={checkIsDirtyForm}>{t("cancel")}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <SaveConfirmModal
        isOpen={isOpenWarning}
        onClose={closeWarningModal}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </>
  );
};

export default NoteAddModal;
