/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import { FiUpload } from "react-icons/fi";
import { uploadAssistantImageAudio } from "services/assitant";
import { FaCheckCircle } from "react-icons/fa";
import Loader from "components/Loader/Loader";
const UploadSelectAssistantImageModal = ({
  isOpen,
  handleClose,
  formik,
}: any) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(
    formik.values.assistant_image || ""
  );

  const systemImages = Array.from({ length: 23 }, (_, i) => ({
    url: `https://precallai.s3.ap-south-1.amazonaws.com/assistant_profile/${
      i + 1
    }.jpeg`,
    id: i + 1,
  }));
  const resetCloseModal = () => {
    handleClose();
  };
  const handleImageSelect = (image: string) => {
    setSelectedImage(image);
    formik.setFieldValue("assistant_image", image);
    handleClose();
  };
  const uploadAssistantImage = (file: any) => {
    setIsLoading(true);
    uploadAssistantImageAudio(file)
      .then((response) => {
        setIsLoading(false);
        if (response.filePath) {
          formik.setFieldValue("assistant_image", response?.filePath);
          handleClose();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
  return (
    <>
      {isLoading && <Loader />}
      <Drawer
        placement="right"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={resetCloseModal}
        size={"xxl"}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent maxW={"600px"} className="dark:bg-navy-900">
          <DrawerHeader>{t("assistantForm.chooseAssistantImage")}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody pb={6}>
            <div className="mt-10 flex flex-wrap gap-4">
              <label className="flex h-16 w-16 cursor-pointer items-center justify-center rounded-full border-2 border-solid">
                <FiUpload className="text-2xl text-gray-600" />
                <input
                  id="uploadedImage"
                  name="uploadedImage"
                  type="file"
                  accept=".jpeg, .jpg, .png, .gif"
                  onChange={(event) => {
                    if (event.currentTarget.files) {
                      const file = event.currentTarget.files?.[0];
                      if (file && allowedImageTypes.includes(file.type)) {
                        uploadAssistantImage(file);
                      } else {
                        toast({
                          description: t("assistantForm.invalidImageType"),
                          status: "error",
                          duration: 8000,
                          isClosable: true,
                          position: "top-left",
                        });
                      }
                    }
                  }}
                  className="hidden"
                />
              </label>
              {systemImages.map((image) => (
                <div className="relative" key={image.id}>
                  <img
                    src={image.url}
                    alt={`System ${image.id}`}
                    className={`h-16 w-16 cursor-pointer rounded-full`}
                    onClick={() => handleImageSelect(image.url)}
                  />
                  {selectedImage === image.url && (
                    <FaCheckCircle className="absolute bottom-0 right-0 h-4 w-4 text-blue-700" />
                  )}
                </div>
              ))}
            </div>
          </DrawerBody>

          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <Button onClick={resetCloseModal}>{t("cancel")}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default UploadSelectAssistantImageModal;
